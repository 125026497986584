import React, {Fragment, useEffect} from 'react'
import {Button, Spin} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import _ from 'lodash'
import * as DB from 'actions/entities'
import {useDispatch, useSelector} from 'react-redux'
import {Redirect} from 'react-router-dom'
import useAsyncEffect from 'hooks/use_async_effect'
import {getMemory} from 'actions/general'
import {API} from '@eitje/easy_api'

const AuthProvider = ({children}) => {
  const token = useSelector(state => state.auth.token)
  const loading = useSelector(state => state.app.loading)
  
  useAsyncEffect(async () => {
    if(token) {
      await API.index('environments')
      API.index('organisations')
      API.index('billing_agents')
      API.index("billing_activities")
      getMemory()
    }
  }, [token])
  const dispatch = useDispatch()
  if(!token) return <Redirect to="/login"/>
	return (
		<Fragment>
    <Spin spinning={loading}>
  			{children}
    </Spin>
		</Fragment>
		)
}

export default AuthProvider;
