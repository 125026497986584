import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {all} from '@eitje/easy_api'
import {useTable} from 'components/general/table'
import {navigateModal} from 'actions/routing'
import {makeRowProps} from 'pages/billing/shared'

const fields = [
  'id',
  'company_name',
  'mollie_mandate',
  'env_names',
  'ignore_mollie',
  'ignore_vat',
  'is_custom',
  'account_nr',
  'account_name',
  'street',
  'house_number',
  'city',
  'postal_code',
]

const searchProps = {searchField: ['company_name', 'name', 'company_name']}

const Infos = ({items}) => {
  const defaultItems = useSelector(state => all(state, 'billingInfos'))
  items = items ?? defaultItems
  const rowProps = makeRowProps('infos')
  const {table, searchInput} = useTable({items, fields, searchProps, rowProps})
  return (
    <div style={{marginTop: 50}}>
      {searchInput}
      {table}
    </div>
  )
}

export default Infos
