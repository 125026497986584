import _ from 'lodash'

const translations = {
  table: {
    discount_amt: 'Korting €',
    discount_pct: 'Korting %',
    reduction_amt_per_user: 'Korting per user',
    ignore: 'Negeer',
    activity_based: 'Activiteit',
    fixed_amt_per_env: 'Vaste prijs',
    name: 'naam',
    company_name: 'naam',
    mollie_mandate: 'mandaat',
    iban: 'iban',
    kvk_nr: 'kvk',
    house_number: 'huis nr',
    amtEnvs: 'envs',
    price_per_user: '$ per user',
    onboarding_mode: 'Inrichtingsmodus',
    organisation: {
      name: 'org',
    },
    days_remaining: 'demo periode',
    signing_status: 'signing',
    activity_user_count: 'Active',
    shift_user_count: 'Shift',
    auth_user_count: 'Auth',
    total_user_count: 'Total users',
    confirmed_user_count: 'Confirmed',
    has_uren: 'uren',
    has_vloer: 'vloer',
    created_at: 'gestart',
    'manual_invoicable?': 'iDeal',
    formatted_invoice_number: 'factuurno',
    price_reduction: 'korting',
    billing_date: 'datum',
    staffel_amt: 'staffel',
    env_names: 'env',
    current_reminder_nr: 'herinnering',
    sent_time: 'gestuurd',
    billingInfos: {
      length: 'infos',
    },
    billingAgreements: {
      length: 'agreements',
    },
    billingDistributions: {
      length: 'distributions',
    },
  },
}

export const t = text => _.get(translations, text) || parseText(text)

const parseText = text => {
  if (!_.isString(text) || text.includes(' ')) return text // trans string never has whitespace
  const split = text.split('.')
  return split[split.length - 1] // to get out prefixes like table.
}
