import {DropdownPicker} from '@eitje/form-fields-web'
import utils from '@eitje/web_utils'
import {Button} from 'antd'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React from 'react'
import {Link as RouterLink, Redirect as RouterRedirect, Route, Switch, useHistory, useLocation} from 'react-router-dom'
import {genericHashLink} from 'react-router-hash-link'

const fixRelPath = (to, loc) => {
  if (to[0] != '/') {
    const extra = loc.pathname[loc.pathname.length - 1] == '/' ? '' : '/'
    to = `${loc.pathname}${extra}${to}`
  }
  return to
}

const useActualTo = to => {
  const loc = useLocation()
  if (_.isObject(to)) {
    const {pathname} = to
    to['pathname'] = fixRelPath(pathname, loc)
  } else {
    to = {pathname: fixRelPath(to, loc)}
  }

  return to
}
export const Redirect = ({keepState = true, to, ...props}) => {
  const actualTo = useActualTo(to)
  const currentState = useLocation().state
  if (keepState && !to.state) {
    actualTo.state = currentState
  }

  return <RouterRedirect to={actualTo} {...props} />
}

export const Link = ({text, children, to, ...rest}) => {
  const actualTo = useActualTo(to)
  // TODO: make relative urls work, also with params
  return (
    <RouterLink to={actualTo} {...rest}>
      {children ? children : !!text && <p> {t(text)} </p>}
    </RouterLink>
  )
}

const HashLink = genericHashLink(Link)

export const RouteSel = ({items, ignorePath, ...rest}) => {
  const loc = useLocation()
  const hist = useHistory()
  const currentVal = loc.pathname.replace(ignorePath, '')

  return <DropdownPicker value={currentVal} items={items} {...rest} onChange={val => hist.push(val)} />
}

export const ModalRedirect = () => {
  let pn = '/'
  const loc = useLocation()
  const bg = loc?.state?.background
  if (bg) {
    pn = bg.pathname
  }

  return <RouterRedirect to={pn} />
}

export const ModalLink = ({to, keepLoc, hash, state = {}, ...rest}) => {
  // we used to determine the previousLoc based on the keepLoc prop, but it seems to be simpler than that:
  // keep loc if you're already in a modal and don't if you're not
  const loc = useLocation()
  const previousLoc = keepLoc ? loc : loc?.state?.background || loc
  return (
    <HashLink className="modalLink" to={{pathname: to, hash, state: {modalRedirect: true, background: previousLoc, ...state}}} {...rest} />
  )
}

export const LinkButton = ({to, exact = true, text, children, ...rest}) => {
  const childs = text ? t(text) : children
  return (
    <Link to={to} exact={exact}>
      <Button type="primary" {...rest} children={childs} />
    </Link>
  )
}

export const ModalLinkBtn = ({to, keepLoc, text, children, ...rest}) => {
  const childs = text ? t(text) : children
  return (
    <ModalLink to={to} keepLoc={keepLoc}>
      <Button type="primary" {...rest} children={childs} />
    </ModalLink>
  )
}

const makeTrans = (key, item) => t(key, {item: ` $t(routing.${item})`})

const getExtra = loc => {
  const last = loc?.pathname?.split('/')
  return last[last.length - 1]
}

export const RouteSelect = ({baseUrl, items, shouldTranslate = true, formKey = 'showPer', ...rest}) => {
  const hist = useHistory()
  const mapped = items.map(i => {
    const isObj = _.isObject(i)
    const label = shouldTranslate ? makeTrans(formKey, isObj ? i.label : i) : i.label
    return isObj ? {...i, label} : {value: i, label}
  })

  const currentLabel = getExtra(hist.location)
  let currentVal = items.find(i => i == currentLabel || i?.label === currentLabel)
  if (_.isObject(currentVal)) currentVal = currentVal.value

  return (
    <DropdownPicker
      valueField="value"
      labelField="label"
      value={currentVal}
      items={mapped}
      {...rest}
      onChange={val => hist.push(!baseUrl ? val : `${baseUrl}${val ? `/${val}` : ''}`)}
    />
  )
}

export const Routes = ({path, routes, extraPath, routeProps = {}}) => {
  const extra = routes.filter(r => r.extra)
  return (
    <Switch>
      {routes.map(r => (
        <Route exact path={makePath(path, r)} render={props => <r.component {...props} {...routeProps} />} />
      ))}
      {extra.map(r => (
        <Route exact path={makePath(path, r, r.extraPath || extraPath)} render={props => <r.extra {...props} {...routeProps} />} />
      ))}
    </Switch>
  )
}

export const SideMenu = ({path, extraPath, namespace, MenuItem = 'p', keepState = true, routeProps, warning, style = {}, routes = []}) => {
  const loc = useLocation()
  return (
    <div>
      {routes.map(r => {
        const finalPath = makePath(path, r)
        const otherPath = makePath(path, r, r.extraPath || extraPath)
        const isActive = isPathActive(loc.pathname, finalPath, otherPath)

        const data = utils.funcOrVal(r.data, routeProps) || {}
        let {count = 0, items} = data
        if (!count && items) count = items.length
        const hasWarning = utils.funcOrVal(warning, data)

        const {color, backgroundColor, border} = getColors(isActive, hasWarning)
        let condStyle = {}

        if (backgroundColor) condStyle['backgroundColor'] = backgroundColor

        const elementStyle = {
          ...condStyle,
          fontSize: 12,
          padding: '8px 12px',
          fontWeight: isActive || hasWarning ? 600 : 400,
          letterSpacing: -0.2,
          color,
          border,
          margin: '4px 12px 4px 8px',
          borderRadius: 4,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          justifyContent: 'space-between',
        }

        const transKey = r.name || r
        const namespaceTrans = `sidemenu.${namespace}.${transKey}`
        const normalTrans = `sidemenu.${transKey}`

        const elementName = namespace ? t(namespaceTrans, t(normalTrans)) : t(normalTrans)

        return (
          <div style={style} className="">
            <Link to={{pathname: finalPath, state: loc.state}}>
              <MenuItem style={elementStyle}>
                {elementName}
                {!!count && count > 1 && <span> ({count}) </span>}
                {hasWarning && (
                  <div className="warningCircle fRow aCen jCen">
                    <p>!</p>
                  </div>
                )}
              </MenuItem>
            </Link>
          </div>
        )
      })}
    </div>
  )
}

const trailingSlash = /\/$/g

const isPathActive = (currentPath, firstPath, extraPath) => {
  // we'll remove the trailing slash from the currentPath
  const sanitizedPath = currentPath.replace(trailingSlash, '')
  firstPath = firstPath.replace(trailingSlash, '')
  extraPath = extraPath.replace(trailingSlash, '')
  return sanitizedPath === firstPath || sanitizedPath == extraPath
}

function getColors() {
  return {color: getColor(...arguments), backgroundColor: getBgColor(...arguments), border: getBorder(...arguments)}
}

const getBgColor = (isActive, hasWarning) => {
  if (hasWarning) return '#ff002020'
  if (isActive) return '#0496ff'
}

const getColor = (isActive, hasWarning) => {
  return hasWarning ? '#ff0020' : isActive ? '#fff' : '#2b2b2b'
}

const getBorder = (isActive, hasWarning) => {
  return hasWarning ? '1px solid #ff002050' : isActive ? '1px solid #0496ff' : '1px solid #fff'
}

const makePath = (basePath, pathObj, ...subPaths) => {
  if (!utils.exists(pathObj)) return `${basePath}`

  if (_.has(pathObj, 'path') && !pathObj.path) return `${basePath}`

  const path = pathObj.path || pathObj.name || pathObj
  let fullPath = `${basePath}/${path}/`
  if (subPaths) {
    fullPath += `${subPaths.join('/')}`
  }
  return fullPath
}
