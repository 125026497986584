import React, {Fragment, useEffect} from 'react'

const K_WIDTH = 15;
const K_HEIGHT = 15;


export const Pin = ({env, $hover}) => 
  <div style={{...pinStyle, backgroundColor: env.status === 'actief' ? "#0496ff" : "#F6B271"}}>
    {$hover && <span> {env.naam} </span>}
  </div>

export const BoatPin = ({item, $hover}) => {
useEffect(() => {
  if($hover) {
    window.alert(`naam: ${item['NAAM']}, tel: ${item['BHR_TEL_NR']},
                  afst: ${item['AFST_BED']}, adr: ${item['ADRES']}, pc: ${item['POSTCODE']}   `)
  }
}, [$hover])
return (
<Fragment>
  <div style={{...boatPinStyle, backgroundColor: "#A2A832"}}>
  </div>
  {false  &&  
    <div style={{zIndex: 1000, height: '100px', width: '200px', backgroundColor: 'white'}}> 
      <p> naam:  </p>
      <p> tel: {item['BHR_TEL_NR']} </p>
      <p> afst: {item['AFST_BED']} </p>
      <p> adr: {item['ADRES']} </p>
      <p> pc: {item['POSTCODE']} </p>
      
    </div>}

</Fragment>
)
}

const boatPinStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH / 1.7,
  height: K_HEIGHT / 1.7,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '1px solid black',
  borderRadius: K_HEIGHT,
  backgroundColor: '#FFE5E5',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 25,
  fontFamily: ['Spectral', 'serif'],
  fontWeight: 'bold',
  padding: 2
};

const pinStyle = {
  // initially any map object has left top corner at lat lng coordinates
  // it's on you to set object origin to 0,0 coordinates
  position: 'absolute',
  width: K_WIDTH,
  height: K_HEIGHT,
  left: -K_WIDTH / 2,
  top: -K_HEIGHT / 2,

  border: '1px solid black',
  borderRadius: K_HEIGHT,
  backgroundColor: '#FFE5E5',
  textAlign: 'center',
  color: '#3f51b5',
  fontSize: 25,
  fontFamily: ['Spectral', 'serif'],
  fontWeight: 'bold',
  padding: 2
};

export const createOpts = (maps, styles = mapStyles) => {
  return {
    zoomControlOptions: {
      position: maps.ControlPosition.RIGHT_CENTER,
      style: maps.ZoomControlStyle.SMALL
    },
    mapTypeControlOptions: {
      position: maps.ControlPosition.TOP_RIGHT
    },
    disableDefaultUI: true,
    styles
  };
}

const mapStyles = [
            {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.fill', stylers: [{color: 'white'}]},
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text',
              stylers: [{color: 'white'}]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563', visibility: 'off'}]
            },
            {
              featureType: 'poi',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{color: '#263c3f'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{color: '#6b9a76'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{color: '#38414e'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{color: '#212a37'}]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{color: '#9ca5b3'}]
            }, 
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{color: '#746855'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{color: '#1f2835'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{color: '#f3d19c'}]
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{color: '#2f3948'}]
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563'}]
            },
            {
              featureType: 'water',
            elementType: 'geometry',
              stylers: [{color: '#17263c'}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{color: '#515c6d'}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [{color: '#17263c'}]
            }
          ]



/// BOAT MAP


export const baseStyle = [
            {elementType: 'geometry', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.stroke', stylers: [{color: '#242f3e'}]},
            {elementType: 'labels.text.fill', stylers: [{color: '#746855'}]},
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563'}]
            },
            {
              featureType: 'poi',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'geometry',
              stylers: [{color: '#263c3f'}]
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text.fill',
              stylers: [{color: '#6b9a76'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry',
              stylers: [{color: '#38414e'}]
            },
            {
              featureType: 'road',
              elementType: 'geometry.stroke',
              stylers: [{color: '#212a37'}]
            },
            {
              featureType: 'road',
              elementType: 'labels.text.fill',
              stylers: [{color: '#9ca5b3'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry',
              stylers: [{color: '#38414e'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'geometry.stroke',
              stylers: [{color: '#1f2835'}]
            },
            {
              featureType: 'road.highway',
              elementType: 'labels.text.fill',
              stylers: [{color: '#f3d19c'}]
            },
            {
              featureType: 'transit',
              elementType: 'geometry',
              stylers: [{color: '#2f3948'}]
            },
            {
              featureType: 'transit.station',
              elementType: 'labels.text.fill',
              stylers: [{color: '#d59563'}]
            },
            {
              featureType: 'water',
              elementType: 'geometry',
              stylers: [{color: '#ffff00'}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.fill',
              stylers: [{color: '#A2A832'}]
            },
            {
              featureType: 'water',
              elementType: 'labels.text.stroke',
              stylers: [{color: '#000000'}]
            },
            // RESET DEFAULTS BELOW
            {
              featureType: 'road',
              elementType: 'all',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'poi',
              elementType: 'labels',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'administrative',
              elementType: 'labels',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'landscape',
              elementType: 'labels',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'road',
              elementType: 'labels',
              stylers: [{visibility: 'off'}]
            },
            {
              featureType: 'transit',
              elementType: 'labels',
              stylers: [{visibility: 'off'}]
            }
            // SET RELEVANT CHILDREN TO CUSTOM VALUES


            
        

             // {
             //  featureType: 'country.geometry',
             //  elementType: 'geometry.stroke',
             //  stylers: [{color: '#A2A832'}]
             // }
          ]


export const placeStyle = [...baseStyle, {
              featureType: 'administrative.locality',
              elementType: 'labels',
              stylers: [{visibility: 'on'}, {color: '#A2A832'}]
            },
            {
              featureType: 'administrative.locality',
              elementType: 'labels.text.stroke',
              stylers: [{visibility: 'on'}, {color: '#242f3e'}]
            }
            ]
