import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import api from 'initializers/api'
import {persist, store, history} from 'index'
import {lastDateSelector} from 'selectors/rev'
// import { useSelector } from 'react-redux'

import * as jsUtils from 'utils/jsutils'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import Chart from 'chart.js'
import moment from 'moment'
import useAsyncEffect from 'hooks/use_async_effect'
import useSelection from 'antd/lib/table/hooks/useSelection'
import {useSelector} from 'react-redux'
import DateGraphContainer from 'components/date_graph_container'

const Stats = () => {
  const [isAnalytics, setAnalytics] = useState(false)
  const [isMonthView, setMonthView] = useState(true)
  const [anaData, setAna] = useState([])
  const [kind, setKind] = useState('amt_users')

  const monthlyData = useSelector(state => state.rev.monthly_revs).filter(d => d.amt)
  const dailyData = useSelector(state => state.rev.daily_revs).filter(d => d.date)

  const date = useSelector(state => lastDateSelector(state))

  let data = isAnalytics ? anaData : isMonthView ? monthlyData : dailyData

  useAsyncEffect(async () => {
    if (!isMonthView) return
    const res = await api.get(`stats/monthly_activity`)
    if (res.ok) {
      store.dispatch({type: 'UPDATE_MONTHLY_REV', rev: res.data.items})
    }
  }, [isMonthView])

  useAsyncEffect(async () => {
    if (isMonthView) return
    const addDay = moment(date)
      .add(1, 'd')
      .format('YYYY-MM-DD')
    const res = await api.get(`stats/daily_activity`, {from: addDay})
    if (res.ok) {
      store.dispatch({type: 'UPDATE_DAILY_REV', rev: res.data.items})
    }
  }, [isMonthView])

  useEffect(() => {
    const newKind = isAnalytics ? 'auth' : 'amt_users'
    setKind(newKind)
  }, [isAnalytics])

  console.log(monthlyData)

  return (
    <div className="topDiv" style={{}}>
      <Button onClick={() => store.dispatch({type: 'RESET_REV'})}> Reset </Button>
      <Button onClick={() => setAnalytics(!isAnalytics)}> Switch to {isAnalytics ? 'money' : 'analytics'} </Button>
      <DateGraphContainer data={data} kind={kind} setKind={setKind} />
      {!isAnalytics && <Button onClick={() => setMonthView(!isMonthView)}> Switch to {isMonthView ? 'per day' : 'per month'} </Button>}
    </div>
  )
}

export default Stats
