import utils from '@eitje/web_utils'
import PickerItem from 'components/list_select/picker_item'
import {useBoolState} from '@eitje/react-hooks'
import useGroupedItems from 'hooks/use_grouped_items'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React from 'react'
import FlatListSelect from './flat_list_select'
import 'styles/app/components/lists/grouped_list_select.less'

export const GroupedListSelect = props => {
  const {hideOrphans, unassignedLabel = t('unassigned')} = props
  const unassignedGroup = {label: unassignedLabel}
  const {groups, visibleGroups, getItemsByGroup, nonGroupedItems} = useGroupedItems(props)

  return (
    <div className="grouped-list-select">
      {groups.map(g => (
        <GroupedListItem group={g} {...props} items={getItemsByGroup(g.id)} visible={visibleGroups.includes(g)} />
      ))}

      {!hideOrphans && nonGroupedItems.length > 0 && <GroupedListItem {...props} group={unassignedGroup} visible items={nonGroupedItems} />}
    </div>
  )
}

const GroupedListItem = props => {
  const {
    group,
    items,
    visible,
    onChange,
    groupSelectable,
    single,
    countRight,
    labelVisible,
    groupLabelVisible = labelVisible,
    value,
    collapseGroups,
    disabled,
    hasIcon,
    selectRight,
    itemDisabled = _.noop,
  } = props
  const [isExpanded, toggleCollapsed = _.noop] = useBoolState(!collapseGroups)
  if (!visible) return null

  const itemValues = items.map(i => i.value)
  const groupSelectedCount = _.intersection(value, itemValues).length
  const hasCount = !!(!groupSelectable && groupSelectedCount && !single)

  const validItems = items.filter(i => !itemDisabled(i))

  const _disabled = disabled || validItems.length == 0

  const classNames = utils.makeCns(
    'grouped-list-select-group',
    isExpanded && 'expanded',
    hasCount && 'selected-within',
    _disabled && 'disabled',
  )

  const countEl = <p className="grouped-list-select-label-count">{groupSelectedCount}</p>
  const onClick = collapseGroups ? toggleCollapsed : _.noop

  const _selectAll = e => {
    e.stopPropagation()
    return onChange(validItems.map(i => i.value))
  }

  const selectGroup = groupSelectable && (
    <div onClick={_selectAll} className="grouped-list-select-label-checkbox">
      <PickerItem isSelected={groupSelectedCount >= itemValues.length} item={group} />
    </div>
  )

  return (
    <div className={classNames}>
      <div onClick={onClick} className="grouped-list-select-label-container">
        {!selectRight && selectGroup}

        {hasCount && !countRight && countEl}

        {hasIcon && !hasCount && <img className="grouped-list-select-label-icon" src={hasIcon} />}
        {groupLabelVisible && group.label && <p className="grouped-list-select-label">{group.label}</p>}
        {hasCount && countRight && countEl}

        {selectRight && selectGroup}

        {collapseGroups && (
          <div className="grouped-list-collapser-container">
            <img className="grouped-list-collapser-icon" src="/images/dropDown1.png" />
          </div>
        )}
      </div>

      {isExpanded && <FlatListSelect {...props} items={items} />}
    </div>
  )
}
