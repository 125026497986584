import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import useGet from 'hooks/use_get'
import ChartContainer from 'components/chart/index'
import Header from 'cores/trial_conversion/components/header'

const Page = () => {
  const {data, loading} = useGet(`trial_conversion/aggregates`)
  if (loading) return <p> loading.. </p>
  const {months} = data
  return (
    <div>
      <ChartContainer data={months} />
      <p> yo </p>
    </div>
  )
}

export default Page
