import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'
import _ from 'lodash'

import {useForm, ContextForm} from '@eitje/form'
import {flexRender} from '@tanstack/react-table'
import TableHeader from './header'
import './styles/index.less'

const TableHeaders = ({rows, headers = []}) => {
  return (
    <thead className="eitje-table-header-wrapper">
      <tr className="eitje-table-header">
        {headers.map(h => (
          <TableHeader rows={rows} header={h} />
        ))}
      </tr>
    </thead>
  )
}

export const TranslatedHeader = ({header, context}) => {
  const headerVal = header.column.columnDef.header
  const val = _.isFunction(headerVal) ? headerVal(context) : headerVal
  return <HeaderTitle val={val} context={context} />
}

export const HeaderTitle = ({val, context}) => {
  const {tableName, recordType} = context.table.options.meta
  return t(`table.${tableName}.headers.${val}`, t(`records.${recordType}.fields.${val}`))
}

export const Aggregates = ({fn, rows, column, cellFn, cellFnOpts = {}}) => {
  const val = fn.name == 'count' ? rows.length : fn(column.id, null, rows)

  const {hasAggCell, cell, aggregatedCell} = column.columnDef
  const defaultCellFn = hasAggCell ? aggregatedCell : cell
  const _cellFn = cellFn || defaultCellFn

  return flexRender(_cellFn, {getValue: () => val, cell, rows, column, ...cellFnOpts})
}

export default TableHeaders
