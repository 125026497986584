import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useExtend from 'hooks/use_extend'
import useAll from 'hooks/use_all'
import utils from '@eitje/web_utils'
import useTable from 'hooks/use_table'
import {ModalLink} from 'components/routing'

export const fields = [
  'naam',
  'id',
  'status',
  'activity_user_count',
  'total_user_count',
  'created_at',
  'pct_active_users',
  'shift_user_count',
  'auth_user_count',
  'confirmed_user_count',
  'onboarding_mode',
  'org',
  'location',
  'signing_status',
  'days_remaining',
  'has_uren',
  'has_vloer',
]
export const activeFields = fields.slice(0, 7)

export const searchProps = {
  searchField: 'naam',
}

export const rowProps = {
  onClick: i => navigateModal(`/environments/${i.id}`),
}

const useEnvTable = ({items}) => {
  return useTable({items, fields, name: 'envTable', searchProps, rowProps})
}

export default useEnvTable
