import {all, find, where} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import _ from 'lodash'
import moment from 'moment'
import pluralize from 'pluralize'
import createCachedSelector from 're-reselect'
import {createSelector} from 'reselect'
import {makeRange} from 'initializers/moment'

const envsSelector = state => all(state, 'environments')
const orgsSelector = state => all(state, 'organisations')

export const prettyEnvsSelector = createSelector(
  envsSelector,
  envs => envs.map(e => ({...e, naam: e.naam.trim()})),
)

export const prettyOrgsSelector = createSelector(
  orgsSelector,
  orgs => orgs.map(e => ({...e, name: e.name.trim()})),
)

export const selfSelector = state => state.auth.user || {}

export const extendSelector = createCachedSelector(
  all,
  (state, tableName) => tableName,
  (state, tableName, items) => items,
  (mergeItems, tableName, items) => extendResources(mergeItems, tableName, items),
)((state, tableName, items) => `${tableName}-${JSON.stringify(items)}`)

const extendResources = (mergeItems, tableName, items) => {
  const singularName = pluralize.singular(tableName)
  return items.map(i => {
    // this is specifically made for billing/orgs currently
    const mergeItem = mergeItems.find(i2 => i2.organisation_ids.includes(i.id))
    const otherMergeItems = mergeItems.filter(i2 => _.intersection(i2.environment_ids, i.environment_ids).length > 0)
    const finalMergeItems = _.uniq([...otherMergeItems, mergeItem].filter(Boolean), 'id')
    return {...i, [tableName]: finalMergeItems, [singularName]: mergeItem}
  })
}

export const dateInRange = createCachedSelector(
  all,
  (state, key) => key,
  (state, key, query) => query,
  (state, key, query, opts = {}) => opts,
  (items = [], key, query, opts) => filterItemsDate(items, query, opts),
)((state, key, query, opts = {}) => `${key}-${JSON.stringify(query)}`)

const filterItemsDate = (items, query, opts) => {
  const keys = Object.keys(query)
  if (keys.includes('start_date') && keys.includes('end_date')) {
    return filterItemsDateRange(items, query, opts)
  }
}

const filterItemsDateRange = (items, {start_date, end_date}, {field = 'date'}) => {
  const range = makeRange(start_date, end_date)
  return items.filter(i => {
    const itemDate = moment(i[field])
    return range.contains(itemDate)
  })
}

export const newExtendSelector = createCachedSelector(
  all,
  (state, tableName) => tableName,
  (state, tableName, items) => items,
  (state, tableName, items, opts = {}) => opts,
  (mergeItems, tableName, items, opts) => newExtend(items, tableName, mergeItems, opts),
)(buildCacheKey)

function buildCacheKey() {
  let str = ''
  const args = Array.from(arguments).slice(1, arguments.length) // first arg is always state
  args.forEach(arg => {
    str += `${makeCacheItem(arg)}-` // - is important to ensure 64 46 doesnt produce same result a 6 464
  })
  const key = str.hashCode()
  console.log(`cacheKey: ${key}`)
  return key
}

const makeCacheItem = arg => {
  if (_.isNumber(arg) || _.isString(arg)) return arg
  if (_.isArray(arg)) return arrToCacheKey(arg) // use char that's never used in strings to prevent potential conflict
  if (_.isPlainObject(arg)) return JSON.stringify(arg)
}

const arrToCacheKey = arr => {
  return arr.map(a => makeCacheItem(a))
}

const newExtend = (items, mergeName, itemsToMerge, opts) => {
  const isSingular = pluralize.isSingular(mergeName)
  const singularName = pluralize.singular(mergeName)
  if (isSingular) return extendSingular(items, singularName, itemsToMerge, opts)
  // const pluralName = pluralize.plural(mergeName)
  return extendMulti(items, singularName, itemsToMerge)
}

// both singular & multi work one-way.. would be fucking awesome to have it two-way, but..:
// either we need to pass both table names to useExtend (sucks balls)
// or we need to get the schema from the back & use that (fully 'mazeballs but also a bit heavy mayb)

const extendSingular = (items, mergeName, itemsToMerge, {spread}) => {
  const field = `${mergeName}_id`
  return items.map(i => {
    const relevantItem = itemsToMerge.find(i2 => i2.id == i[field])
    const toAdd = spread ? relevantItem : {[mergeName]: relevantItem}
    return {...i, ...toAdd}
  })
}

const extendMulti = (items, mergeName, itemsToMerge) => {
  const field = `${mergeName}_ids`
  const pluralName = pluralize.plural(mergeName)
  return items.map(i => {
    const relevantItems = itemsToMerge.filter(i2 => i[field].includes(i2.id))
    return {...i, [pluralName]: relevantItems}
  })
}
