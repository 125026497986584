import {getToken, logout} from 'actions/auth'
import {create} from 'apisauce'
import Reactotron from 'reactotron-react-js'
import * as jsUtils from 'utils/jsutils'
const stage = process.env.REACT_APP_STAGE || process.env.NODE_ENV

const prefEnv = 'development'
const actualEnv = stage === 'development' ? prefEnv : stage
let domain
let protocol = 'https://'
switch (actualEnv) {
  case 'development':
    domain = 'localhost:3000/api/admin/'
    protocol = 'http://'
    break
  case 'test':
    domain = 'testapi.eitje.app/api/admin/'
    break
  case 'test2':
    domain = 'eitje-test-api2.eitje.app/api/admin/'
    break
  case 'staging':
    domain = 'staging-api.eitje.app/api/admin'
    break
  case 'mirror':
    domain = 'mirror-api.eitje.app/api/admin'
    break
  case 'production':
    domain = 'api.eitje.app/api/admin/'
    break
  default:
    domain = 'api.eitje.app/api/admin/'
}

export const url = `${protocol}${domain}`
export const ENV = actualEnv
export const DOMAIN = domain
export const HOST = url
export const fileDomain = url.replace('app/api/admin', 'app')

const api = create({
  baseURL: HOST,
  headers: {'Content-Type': 'application/json', credentials: 'same-origin', 'Access-Control-Allow-Origin': '*'},
})

async function changeTokenHeader(req) {
  if (req.url !== 'oauth/token' && req.url !== 'auth' && req.url !== 'users/sign_up' && req.url !== 'auth/confirmed') {
    if (!req.data) req.data = {}
    const token = getToken()
    if (token) {
      req.data['access_token'] = token
      if (req.params) req.params['access_token'] = token
    }
  }
}

const authMonitor = res => {
  if (res.status === 401 || res.status === 403) {
    jsUtils.errNotif('auth expired', 'do login again')
    logout()
  }
}
if (Reactotron.apisauce) {
  api.addMonitor(Reactotron.apisauce)
}
api.addAsyncRequestTransform(request => changeTokenHeader(request))
api.addMonitor(authMonitor)

export default api
