import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Form} from 'initializers/form'
import {Input, DatePicker, Select} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import {generateInvoices, sendInvoices} from 'actions/billing'
import {Link, ModalLinkBtn, LinkButton} from 'components/routing'
import utils from '@eitje/web_utils'

const ContainerModal = () => {
	return (
		<Modal title="Situation Room">
			<SituationRoom />
		</Modal>
	)
}

const SituationRoom = () => {
	const {data = {}, retry, loading} = useGet('billing_invoices/run_ready')

	const {generate, pdfs_generated, send, error, insane_records = []} = data
	if (loading) return <p> loading.. </p>
	if (error) return <p> iets misgegaan.. probeer t opnieuw </p>
	if (insane_records.length > 0) return <InsaneRecords items={insane_records} />
	if (!generate) return <GenInvoices {...data} onDone={retry} />
	if (!generate.completed || !pdfs_generated)
		return <InProgress retry={retry} item={generate} text="We are currently generating the invoices" />
	if (send && !send.completed) return <InProgress retry={retry} item={send} text="We are currently sending the invoices" />
	if (!send?.completed && generate.completed)
		return <LinkButton style={{backgroundColor: 'red', borderColor: 'red'}} text={`Start billing run`} to="/get_rich" />
	return <Done send={send} generate={generate} />
}

const InProgress = ({item, text, retry}) => {
	return (
		<div>
			<h3> {text} </h3>
			<p> In progress since: {utils.dateTime(item.created_at)} </p>
			<Button onClick={retry}> Ververs </Button>
		</div>
	)
}

const Done = ({send, generate}) => {
	return (
		<div>
			<h2> Je bent helemaal klaar! </h2>
			<div>
				<p> Factureren gegenereerd op: {utils.dateTime(generate.created_at)} </p>
				<p> Factureren verzonden op: {utils.dateTime(send.created_at)} </p>
			</div>
		</div>
	)
}

const InsaneRecords = ({items}) => {
	return (
		<div>
			<h4>
				Er mag geen mix tussen ignore/non-ignore zijn binnen een distributie. Praktisch betekent dat je over 't algemeen een eigen
				distributie moet maken voor de vestigingen die op true staan. Die mogen wel weer allemaal samen in een distributie zitten
			</h4>
			{items.map(i => (
				<InsaneItem item={i} />
			))}
		</div>
	)
}

const InsaneItem = ({item}) => {
	return (
		<div>
			<p> naam: {item.name} </p>
			<p> envs: {JSON.stringify(item.ignore_overview)} </p>
		</div>
	)
}

const GenInvoices = ({onDone}) => {
	const _generateInvoices = async () => {
		await generateInvoices()
		onDone()
	}

	return <Button onClick={_generateInvoices}> Genereer invoices </Button>
}

// API.index("pending_invoices", {refresh: true})

export default ContainerModal
