import React, {useEffect, useState, Fragment, createContext, useMemo, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import {t} from 'initializers/i18n'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Button, Tooltip, UserImage, EitjeButton, EitjeLabel, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'
import {useForm, ContextForm} from '@eitje/form'
import useFormData from 'hooks/use_form_data'
import DropdownListSelect from 'components/form/dropdown_list_select'
import {store} from 'index'

export const ColumnPicker = ({fields, name, recordType}) => {
  const _name = `${name}_table`
  let activeFields = useSelector(state => state.filters[_name])
  activeFields = !utils.exists(activeFields) ? fields : fields.filter(f => activeFields.includes(f)) // keep sort order of fields order

  const columnItems = fields.map(f => mapField(f, name, recordType))

  debugger
  return (
    <DropdownListSelect
      noSort
      showSearch
      checkboxRight={false}
      value={activeFields}
      Component={ColumnButton}
      onChange={val => store.dispatch({name: _name, type: 'SET_FILTER', value: val})}
      items={columnItems}
    />
  )
}
const ColumnButton = ({onClick, title}) => {
  return (
    <EitjeButton onClick={onClick}>
      <img src="/images/filterTeams.png" style={{width: 12, marginTop: -2, marginRight: 8}} />
      {utils.capitalize(t('editColumns'))}
    </EitjeButton>
  )
}

const mapField = (field, name, recordType) => {
  const _name = t(`table.${name}.headers.${field}`, t(`records.${recordType}.fields.${field}`))
  return {id: field, name: _name}
}
