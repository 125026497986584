import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import {getBilling} from 'actions/billing'

const BillingInfo = ({item}) => {
	useEffect(getBilling, [])
	const billingInfo = useFind('billingInfos', item.billing_info_id)

	return (
		<div>
			<Info item={billingInfo} />
			<Links item={item} />
		</div>
	)
}

const Info = ({item}) => {
	const {company_name, pdf, person_name, emails = [], signer_name, formatted_address} = item
	return (
		<div>
			<Input value={company_name} field="Bedrijfsnaam" readOnly />
			<Input value={person_name} field="Contract persoon facturatie" readOnly />
			<Input value={emails.join(', ')} field="Factuur emails" readOnly />
			<Input value={signer_name} field="Ondertekend door" readOnly />
			<a target="_blank" href={pdf}>
				<Button> Download contract </Button>
			</a>
			<Input value={formatted_address} field="Adresgegevens" readOnly />
		</div>
	)
}

const Links = ({item}) => {
	const {billing_agreement_id, organisation_id, billing_distribution_id, billing_info_id} = item
	return (
		<div>
			<ModalLinkBtn keepLoc to={`/billing/agreements/${billing_agreement_id}`}>
				Open Agreement
			</ModalLinkBtn>
			<ModalLinkBtn keepLoc to={`/billing/infos/${billing_info_id}`}>
				Open Info
			</ModalLinkBtn>
			<ModalLinkBtn keepLoc to={`/billing/distributions/${billing_distribution_id}`}>
				Open Distribution
			</ModalLinkBtn>
			<Link to={`/billing/orgs/${organisation_id}`}>
				<Button type="primary"> Go to organisation </Button>
			</Link>
		</div>
	)
}

export default BillingInfo
