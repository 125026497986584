import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'

export const EmptyPlaceholder = ({title = t('common.placeholder.title'), subtitle = t('common.placeholder.subtitle')}) => {
  return (
    <div className="empty-list">
      <img src="/images/noContent.png" className="empty-list-image" />
      <h4 className="empty-list-title"> {title} </h4>
      <h5 className="empty-list-subtitle"> {subtitle} </h5>
    </div>
  )
}
