import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'
import TableHeaders from './headers/index'
import TableRows from './rows/index'
import {EmptyPlaceholder} from 'components/common/index'
import './styles/index.less'

export const Table = ({
  data,
  firstColumnSticky = true,
  lastColumnSticky,
  headerSticky = true,
  columnWidth,
  firstColumnWidth,
  rowHeight,
  rowGutterHorizontal,
  rowGutterVertical,
  headerHeight,
  tableOffsetTop,
  tableOffsetLeft,
  ...rest
}) => {
  const {placeholderTitle, placeholderSubtitle} = rest
  const {getHeaderGroups, getRowModel} = data
  const headers = getHeaderGroups()[0]?.headers // we dont use header groups
  const meta = headers[0]?.getContext()?.table?.options?.meta
  const isGrouped = headers.find(h => h.column?.getIsGrouped())
  const rows = getRowModel().rows
  console.log(rows)
  const empty = rows.length == 0

  const classNames = utils.makeCns(
    'eitje-table',
    empty && 'eitje-table-no-content',
    isGrouped && 'eitje-table-grouped-rows',
    !isGrouped && 'eitje-table-individual-rows',
    firstColumnSticky && 'eitje-table-first-column-sticky',
    lastColumnSticky && 'eitje-table-last-column-sticky',
    headerSticky && 'eitje-table-header-sticky',
  )

  const columnCount = headers.length - 1 // CSS needs amount of columns that are 1. visible and 2. have an irregular width

  return (
    <table
      style={{
        '--column-count': columnCount,
        '--column-width': columnWidth || 150,
        '--first-column-width': firstColumnWidth || columnWidth || 150,
        '--row-height': rowHeight || 50,
        '--row-gutter-horizontal': rowGutterHorizontal || 8,
        '--row-gutter-vertical': rowGutterVertical || 4,
        '--header-height': headerHeight || rowHeight || 50,
        '--table-offset-left': tableOffsetLeft || 0,
        '--table-offset-top': tableOffsetTop || 0,
      }}
      className={classNames}
    >
      <TableHeaders rows={rows} headers={headers} />
      <TableRows meta={meta} rows={rows} {...rest} />
      {empty && <EmptyPlaceholder title={placeholderTitle} subtitle={placeholderSubtitle} />}
    </table>
  )
}

export default Table
