import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'

const fields = ['name', 'org_names']

const rowProps = {
  onClick: i => navigateModal(`/billing_agents/${i.id}`)
}

const Agents = () => {
  let agents = useAll('billingAgents')
  agents = useExtend(agents, 'organisations').map(i => ({...i, org_names: i.organisations.map(i => i.name)}))
  const {table, searchInput} = useTable({items: agents, fields, rowProps})
  return (
    <div className="topDiv">
      <ModalLinkBtn to="/billing_agents/new" text="new"/>
      {searchInput}

      {table}
    </div>
  )
}

export default Agents
