import React from 'react'
import {useState} from 'react'
import {Dropdown as AntDropdown, Menu} from 'antd'
import utils from '@eitje/web_utils'
import _ from 'lodash'
const {Item} = Menu

const Dropdown = ({elements, children, visibleAfterClick, onVisibleChange = _.noop, visibleKeysAfterClick = [], ...rest}) => {
  visibleKeysAfterClick = utils.alwaysDefinedArray(visibleKeysAfterClick)
  const [visible, setVisible] = useState(false)

  const _onVisibleChange = vis => {
    onVisibleChange(vis)
    setVisible(vis)
  }
  const handleMenuClick = ({key}) => {
    key = Number(key)
    if (visibleAfterClick || visibleKeysAfterClick.includes(key)) return
    setVisible(false)
  }

  return (
    <AntDropdown {...rest} visible={visible} onVisibleChange={_onVisibleChange} overlay={() => makeOverlay(elements, handleMenuClick)}>
      {children}
    </AntDropdown>
  )
}

const makeOverlay = (elements = [], onClick) => {
  return (
    <Menu onClick={onClick}>
      {elements.map((e, idx) => (
        <Item key={idx}> {e} </Item>
      ))}
    </Menu>
  )
}

export default Dropdown
