import {Input} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import React, {Fragment} from 'react'

const maxTwoDigits = /(,\d{1,2})\d*/g
const maxFourDigits = /(,\d{1,4})\d*/g
const maxOneComma = /(,.*?),.*/g
const thousandSep = /\B(?=(\d{3})+(?!\d))/g
const leadingComma = /^,|^\./g
const trailingDot = /\.$/g
const leadingZero = /^0*/g

const dotBeforeMaxTwoNums = /\.(\d{1,2})/g

const allDots = /[\.*]/g
const allCommas = /[,*]/g

const formatNum = (val, maxDigits = maxFourDigits) => {
  val = val.toString()
  return val
    .replace(trailingDot, ',')
    .replace(dotBeforeMaxTwoNums, ',$1')
    .replace(/\.*/g, '')
    .replace(maxOneComma, '$1')
    .replace(maxDigits, '$1')
}
const formatMoney = val => val.replace(thousandSep, '.')
const formatAll = val => formatMoney(formatNum(val, maxTwoDigits))

const toNum = (val, replaceLeadingZero, min, max) => {
  let parsed = val.replace(/[^0-9-,.]/g, '').replace(leadingComma, '')
  parsed = parsed.replace(/--/g, '-')
  if (replaceLeadingZero) {
    parsed = parsed.replace(leadingZero, '')
  }
  parsed = parsed.replace(trailingDot, ',').replace(allDots, '').replace(allCommas, '.')
  if ((min && parsed < min) || (max && parsed > max)) return NaN
  return parsed
}

const InputNumber = props => {
  const {financial, maxNums, min, max, value} = props
  const _val = value || ''

  const val = financial ? formatAll(_val) : formatNum(_val)
  return (
    <Fragment>
      <Input placeholder={financial ? '€' : ''} formatValue={val => toNum(val, financial, min, max)} {...props} displayValue={val} />
    </Fragment>
  )
}

const SalaryInput = props => <InputNumber warning={value => value > 50 && t('bigWage')} financial {...props} />

export {SalaryInput}

InputNumber.defaultProps = {defaultSubmitStrategy: 'blur'}

export default InputNumber
