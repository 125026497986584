import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'initializers/form'
import {Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {ModalLink} from 'components/routing'
import {useSelector} from 'react-redux'
import useParams from 'hooks/use_params'
import useFind from 'hooks/use_find'
import {useTable} from 'components/general/table'
import useExtendOrg from 'hooks/use_extend_org'
import Agreements from 'pages/billing/agreements'
import Distributions from 'pages/billing/distributions'
import {API, useWhere} from '@eitje/easy_api'
import useInvoiceTable from 'pages/billing/invoices/use_invoice_table'
import useSearch from 'hooks/use_search'

const Show = ({org}) => {
  const filters = {organisation_id: org.id}
  useEffect(() => {
    API.index('billing_invoices', {filters})
  }, [])
  const invoices = useWhere('billing_invoices', filters)
  const {table, columnPicker} = useInvoiceTable(invoices)
  return (
    <div>
      {columnPicker}
      {table}
      <Infos org={org} />
    </div>
  )
}

const Infos = ({org}) => {
  const {billingInfos} = org
  const {searchInput, filteredItems = []} = useSearch(billingInfos, {searchField: 'company_name'})
  return (
    <div>
      {searchInput}
      {filteredItems.map(info => (
        <Info item={info} />
      ))}
    </div>
  )
}

const Info = ({item}) => {
  const environment = useFind('environments', {billing_info_id: item.id})
  const {emails, company_name} = item
  return (
    <ModalLink to={`/environments/${environment.id}/billing_info`}>
      <h4> {company_name} </h4>
      <p> {emails} </p>
    </ModalLink>
  )
}

export default Show
