import React, {useState, useEffect, Fragment} from 'react'
import {Button, Tabs, Select as AntSelect, Upload} from 'antd'
import {API} from '@eitje/easy_api'
import {HOST} from 'initializers/api'

import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/web_utils'
import useFind from 'hooks/use_find'
import Modal from 'components/general/modal'
import useParams from 'hooks/use_params'
import useGet from 'hooks/use_get'

const {Option} = AntSelect

const CompetitorImport = ({item}) => {
	const [partner, setPartner] = useState('l1nda')
	const token = useSelector(state => state.auth.token)

	return (
		<Fragment>
			<p>Importeer vanuit ander programma </p>
			<AntSelect value={partner} style={{width: 318, marginRight: 4, position: 'relative', top: 1}} onChange={v => setPartner(v)}>
				{['l1nda', 'booq', 'quinfo', 'nostra', 'shiftbase'].map(opt => (
					<Option value={opt}> {opt} </Option>
				))}
			</AntSelect>

			{partner && (
				<Upload
					data={p => ({excel: p, partner: partner, access_token: token})}
					action={`${HOST}/environments/${item.id}/import_users`}
					type="upload"
				>
					<Button className="orange">Import teamleden</Button>
				</Upload>
			)}
		</Fragment>
	)
}

export default CompetitorImport
