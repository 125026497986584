import React from 'react'
import {DefaultRow} from './row'

export const Checkbox = ({color, selected}) => {
  const className = selected ? 'checkbox-outer selected' : 'checkbox-outer'
  return (
    <div className="check-box-container">
      <div className={className}>
        <div className="checkbox-inner" />
      </div>
    </div>
  )
}

const PickerItem = props => {
  let {onChange, checkboxRight = true, disableCheckBox, item, isSelected, Row = DefaultRow, rowProps} = props

  const checkbox = disableCheckBox ? null : <Checkbox selected={isSelected} />

  const el = item.readOnly ? null : checkbox

  const sharedProps = {...rowProps, ...props, item, isSelected, onChange}

  const row = <Row {...sharedProps} />

  return checkboxRight ? (
    <>
      {row}
      {el}
    </>
  ) : (
    <>
      {el}
      {row}
    </>
  )
}

export default PickerItem
