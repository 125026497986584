import React, {useState, useEffect, useRef, useContext, Fragment} from 'react'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, DatePicker, DropdownPicker, Switch} from '@eitje/form-fields-web'
import moment from 'moment'
import {Button} from 'antd'
import {useSelector} from 'react-redux'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import useParams from 'hooks/use_params'
import {Spin} from 'antd'
import {ModalForm} from 'initializers/form'
import {all, find, where, API} from '@eitje/easy_api'
import InputNumber from 'components/form/input_number'
import {useMergeState} from '@eitje/react-hooks'
import {useBackgroundOrg} from 'hooks/use_background_envs'
import useFind from 'hooks/use_find'
import useExtendOrg from 'hooks/use_extend_org'

const _Form = props => {
  const [state, setState] = useMergeState()

  const {orgId} = useBackgroundOrg()
  const backgroundOrg = useFind('organisations', orgId)
  const extendedOrg = useExtendOrg([backgroundOrg])[0]

  const _billingInfos = useSelector(state => all(state, 'billingInfos'))
  const billingInfos = orgId ? extendedOrg.billingInfos : _billingInfos

  const {billing_info_id} = state
  const billingInfo = useSelector(state => find(state, 'billingInfos', billing_info_id))
  const {organisation_ids = []} = billingInfo
  const org = useSelector(state => find(state, 'organisations', organisation_ids[0]))
  const orgEnvs = useSelector(state => where(state, 'environments', {organisation_id: org.id}))
  const customEnvs = useSelector(state => where(state, 'environments', billingInfo.environment_ids))
  const envs = [...orgEnvs, ...customEnvs]

  return (
    <ModalForm
      mirrorFields={['billing_info_id']}
      setState={setState}
      onSubmit={data => API.update('billing_distributions', data)}
      {...props}
    >
      <DropdownPicker showSearch required labelField="company_name" field="billing_info_id" items={billingInfos} />

      {billing_info_id && (
        <DropdownPicker
          multiple
          required={!props.initialValues}
          labelField="naam"
          label="custom envs"
          items={envs}
          field="environment_ids"
        />
      )}
      <Button submitButton> submit </Button>
    </ModalForm>
  )
}

export default _Form
