import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, Popconfirm} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {t} from 'initializers/i18n'
import {useSelector} from 'react-redux'
import {Tooltip, InfoTooltip} from 'components/common/tooltip'
import utils from '@eitje/web_utils'
export * from 'components/common/eitje_button'
export * from 'components/common/popover'
export * from 'components/common/row'
export {Tooltip, InfoTooltip}

export const DeleteButton = ({title = t('sure'), overlayClassName = '', afterDel, children = t('delete'), onDel, ...rest}) => {
  const _onDel = async e => {
    await onDel(e)
    afterDel && afterDel()
  }

  return (
    <Popconfirm title={title} onConfirm={_onDel} overlayClassName={overlayClassName}>
      <Button className="btn-hover danger" {...rest} children={children} />
    </Popconfirm>
  )
}

export const ConfirmButton = ({title = t('sure'), onClick, className, overlayClassName = 'veedree', ...rest}) => {
  const {disabled} = rest
  const classNames = utils.makeCns('eitje-confirm-button', className)
  return (
    <Popconfirm disabled={disabled} overlayClassName={overlayClassName} title={title} onConfirm={onClick}>
      <Button type="regular" className={classNames} {...rest} />
    </Popconfirm>
  )
}
