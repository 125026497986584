import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link, ModalLinkBtn, LinkButton} from 'components/routing'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {Switch, useLocation, useRouteMatch} from 'react-router-dom'
import {API, backend} from '@eitje/easy_api'
import {PrivateRoute, PublicRoute, ProtectedRoute} from 'components/PrivateRoute'
import BillingAgreements from 'pages/billing/agreements'
import BillingDistributions from 'pages/billing/distributions'
import BillingInfos from 'pages/billing/infos'
import Invoices from 'pages/billing/invoices/index'
import UnpaidInvoices from 'pages/billing/invoices/unpaid'

import EnvCosts from 'pages/billing/costs/index'
import Costs from 'pages/billing/distributions/cost_index'
import BillingOrgs from 'pages/billing/orgs'
import BillingOrg from 'pages/billing/orgs/show'
import useAll from 'hooks/use_all'
import useGet from 'hooks/use_get'
import {generateInvoices, getBilling} from 'actions/billing'
import {DeleteButton} from 'components/ui'
import Dropdown from 'components/dropdown'
import utils from '@eitje/web_utils'
import {CaretDownOutlined} from '@ant-design/icons'

const Billing = () => {
  const loc = useLocation()
  const split = loc.pathname.split('/')[2]
  const name = utils.capitalize(split)

  useEffect(getBilling, [])

  const elements = [
    <Link to="/billing/infos">Infos</Link>,
    <Link to="/billing/agreements">Agreements</Link>,
    <Link to="/billing/distributions">Distributions</Link>,
    <Link to="/billing/orgs">Orgs</Link>,
    <Link to="/billing/invoices">Invoices</Link>,
    <Link to="/billing/costs">Euros</Link>,
    <Link to="/billing/env_costs">Euros per env</Link>,
    <DeleteButton title="This will delete all costs from last month & re-calculate" onDel={() => backend.post('billing_costs/reset')}>
      Reset billing
    </DeleteButton>,

    <ModalLinkBtn text="New Agreement" to="/billing/agreements/new" />,
    <ModalLinkBtn text="New Info" to="/billing/infos/new" />,
    <ModalLinkBtn text="New Distribution" to="/billing/distributions/new" />,
    <ModalLinkBtn text="Situation Room" to="/billing/invoices/situation_room" />,
  ]
  const invoices = useAll('pendingInvoices')
  return (
    <div style={{marginTop: 70}}>
      <Dropdown elements={elements}>
        <div className="headerText fRow aCen">
          <p> {name} </p>
          <CaretDownOutlined style={{paddingLeft: 4, fontSize: 12}} />
        </div>
      </Dropdown>
      <Routes />
    </div>
  )
}

const Routes = () => (
  <Switch>
    <PrivateRoute path="/billing/orgs/:id" component={BillingOrg} />
    <PrivateRoute path="/billing/orgs" component={BillingOrgs} exact />
    <PrivateRoute path="/billing/agreements" component={BillingAgreements} exact />
    <PrivateRoute path="/billing/infos" component={BillingInfos} exact />
    <PrivateRoute path="/billing/distributions" component={BillingDistributions} exact />
    <PrivateRoute path="/billing/invoices" component={Invoices} exact />
    <PrivateRoute path="/billing/invoices/unpaid" component={UnpaidInvoices} exact />
    <PrivateRoute path="/billing/costs" component={Costs} exact />
    <PrivateRoute path="/billing/env_costs" component={EnvCosts} exact />
  </Switch>
)

export default Billing
