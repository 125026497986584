import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useMemo, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'

const getColumns = (savedColumns, columnIds, stickyFields) => {
  const columns = !utils.exists(savedColumns) ? columnIds : columnIds.filter(f => savedColumns.includes(f))
  return [...stickyFields, ...columns].uniq()
}

export const useSavedColumns = ({tableName, columnIds, stickyFields}) => {
  const savedColumns = useSelector(state => state.filters[`${tableName}_table`])
  const activeColumns = useMemo(() => getColumns(savedColumns, columnIds, stickyFields), [savedColumns, columnIds, stickyFields])
  return {activeColumns, savedColumns}
}
