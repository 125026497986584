import {usePicker} from '@eitje/form'
import utils from '@eitje/web_utils'
import useSearch from 'hooks/use_search'
import useToggleState from 'hooks/use_toggle_state'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {useState} from 'react'
import {getNewVal} from './actions'
import {FlatListSelect} from './flat_list_select'
import {GroupedListSelect} from './grouped_list_select'
import {DefaultRow} from './row'
import {SelectedArea} from './selected_area'
import {ClearButton} from 'components/common/index'
import './styles/list_select.less'

const sortItem = (item, sortField) => {
  const val = item[sortField]
  const sortVal = _.isString(val) ? val.toLowerCase() : val
  return sortVal
}

export const ListSelect = props => {
  const [expandedGroups, setExpandedGroups] = useToggleState([])
  const [localValue, setLocalValue] = useState(props.initialValue)
  let {
    items,
    renderRemove,
    disabled,
    labelVisible = true,
    Row = DefaultRow,
    value,
    onChange,
    groupField,
    single,
    selectedAtTop = !single,
    labelField,
    noSort,
    searchProps = {},
    rowProps = {},
    selectedAreaProps = {},
    readOnly,
    sortField = 'label',
    label,
    allowClear,
    max,
    preChildren,
    placeholder,
    showClearButton,
    ...rest
  } = props

  value = utils.alwaysDefArray(props.initialValue ? localValue : value)

  const showSearch = _.has(rest, 'showSearch') ? rest.showSearch : utils.exists(props.searchProps)
  if (!searchProps['searchField']) {
    searchProps['searchField'] = labelField
  }

  const action = (selected, e) => {
    const newVal = single ? selected : getNewVal(value, selected, props)
    const isSelect = newVal.length > value.length
    if (props.initialValue) setLocalValue(newVal)
    if (max && newVal.length > max) {
      utils.errNotif(t('oops'), t('maxSelect', {count: max}))
      return
    }

    onChange(newVal, {changed: selected, isSelect})
  }

  const clear = () => onChange([])

  let {pickerItems, selectedBaseItem, selectedItem, selectedItems} = usePicker(props)

  if (!noSort) {
    pickerItems = _.sortBy(pickerItems, item => sortItem(item, sortField))
  }

  let {filteredItems, searchInput, setSearch} = useSearch(pickerItems, {
    showSearch,
    ...searchProps,
  })

  if (allowClear) {
    filteredItems = [...filteredItems, {value: null, label: t('none')}]
  }

  const classNames = utils.makeCns('list-select-container', !groupField && disabled && 'disabled', readOnly && 'read-only')

  const propz = {
    items: filteredItems,
    onChange: action,
    value,
    disabled,
    labelVisible,
  }

  return (
    <div className={classNames}>
      {selectedAtTop && (
        <SelectedArea placeholder={placeholder} items={selectedItems} renderRemove={renderRemove} action={action} {...selectedAreaProps} />
      )}

      {showSearch && searchInput}
      {label}
      {preChildren}

      {groupField ? <GroupedListSelect {...props} {...propz} /> : <FlatListSelect {...props} {...propz} />}
      {showClearButton && utils.exists(value) && (
        <div className="clear-selection-button-container">
          <ClearButton className="clear-selection-button" width="full-minus-margin" text={t('common.clear')} onClick={clear} />
        </div>
      )}
    </div>
  )
}

export default ListSelect
