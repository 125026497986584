import React, {Fragment} from 'react'
import {Tooltip, InfoTooltip} from 'components/ui'
export const DefaultRow = ({
  selected,
  selectedLblColor = '#2b2b2b',
  unselectedLblColor = '#828282',
  Container = Fragment,
  item,
  children,
  labelStyle,
  childrenAtStart,
  labelVisible,
}) => {
  const {tooltip, label} = item
  return (
    <Fragment>
      <Container>
        {childrenAtStart && children}
        {labelVisible && label && <p className="flat-list-select-item-text">{label}</p>}
        {tooltip && <InfoTooltip title={tooltip} />}
        {!childrenAtStart && children}
      </Container>
    </Fragment>
  )
}

export default DefaultRow
