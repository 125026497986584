import React from 'react'
import { Spin } from 'antd'

import { setup, configure } from '@eitje/form'
import { t } from 'initializers/i18n'
import {history} from 'index'
import {Prompt} from 'react-router-dom'

const a = text => window.alert(a)

setup({ translatorFunc: t, alert: a, Prompt})


const Form = configure()
const ModalForm = props => <Form afterSubmit={history.goBack} {...props}/>

export {Form, ModalForm}
export default Form;
