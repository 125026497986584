import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {API} from '@eitje/easy_api'
import {DeleteButton} from 'components/ui'
import {navigateModal} from 'actions/routing'

export const DeleteItem = ({item, name}) => <DeleteButton onDel={() => API.destroy(`billing_${name}`, item.id) }/>

export const makeRowProps = name => ({
  title: item => 'Acties',
  content: item => <DeleteItem item={item} name={name}/>,
  showActions: true,
  onClick: (i) => navigateModal(`/billing/${name}/${i.id}` )
})