import utils from '@eitje/web_utils'
import React from 'react'
import {isSelected} from './actions'
import PickerItem from './picker_item'
import './styles/flat_list_select.less'
import {Virtuoso} from 'react-virtuoso'
import _ from 'lodash'

export const FlatListSelect = props => {
  return (
    <div className="flat-list-select">
      <Decider {...props} />
    </div>
  )
}

export const FlatListItem = ({item, value, onChange, readOnly, itemDisabled = _.noop, ...rest}) => {
  const _isSelected = isSelected(item, value)
  const disabled = itemDisabled(item)
  const classNames = utils.makeCns('flat-list-select-item', _isSelected && 'selected', disabled && 'disabled')

  const _onChange = e => (readOnly || disabled ? null : onChange(item.value, e))
  return (
    <div className={classNames} onClick={_onChange}>
      <PickerItem key={item.value} isSelected={_isSelected} onChange={_onChange} item={item} {...rest} />
    </div>
  )
}

const Decider = props => {
  const {items, virtual} = props
  if (virtual) return <Virtuoso data={items} itemContent={(index, row) => <FlatListItem key={index} item={row} {...props} />} />
  return items.map(i => <FlatListItem item={i} {...props} />)
}

export default FlatListSelect
