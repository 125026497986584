import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, Select} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {createOpts, Pin} from 'components/google_maps'
import GoogleMapReact from 'google-map-react'

import useAll from 'hooks/use_all'
const {Option} = Select

const envTypes = ['actief', 'demo']

const EnvSelector = ({setter, initVal, place}) => {
  const children = envTypes.map(e => <Option key={e}>{e[0].toUpperCase() + e.slice(1)}</Option>)

  return (
    <Select mode="multiple" allowClear style={{width: '250px'}} placeholder={place} defaultValue={initVal} onChange={envs => setter(envs)}>
      {children}
    </Select>
  )
}

const Page = () => {
  const [envToDisplay, setEnvToDisplay] = useState(envTypes)
  const envs = useAll('environments')

  const act = envs.filter(e => envToDisplay.includes(e.status))

  return (
    <div>
      <div style={{position: 'absolute', top: '100px', left: '200px', zIndex: '300'}}>
        <EnvSelector setter={setEnvToDisplay} initVal={envToDisplay} place={'Select Environments to Display'} />
      </div>
      <div style={{width: '100%', height: '100vh'}}>
        <GoogleMapReact
          defaultCenter={{
            lat: 52.3667,
            lng: 5.3945,
          }}
          bootstrapURLKeys={{
            key: 'AIzaSyAzLn_fILRETVeoyqBKJWsLAXYUAK_Tgsk',
          }}
          zoom={8}
          options={createOpts}
        >
          {act.map(p => (
            <Pin env={p} lat={p.lat} lng={p.lng} />
          ))}
        </GoogleMapReact>
      </div>
    </div>
  )
}

export default Page
