import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API, useGet, useWhere} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import {DeleteButton} from 'components/ui'

const Page = ({item}) => {
	useEffect(() => {
		API.index('notes', {params: {env_id: item.id}})
	}, [])
	const envNotes = useWhere('notes', {resource_id: item.id, resource_type: 'Environment'})
	const orgNotes = useWhere('notes', {resource_id: item.organisation_id, resource_type: 'Organisation'})
	const allNotes = _.orderBy([...envNotes, ...orgNotes], 'created_at')

	debugger
	return (
		<div>
			<NoteForm item={item} />
			{allNotes.map(n => (
				<Note item={n} />
			))}
		</div>
	)
}

const NoteForm = ({item}) => {
	return (
		<Form
			initialValues={{resource_type: 'Environment'}}
			onSubmit={data => API.create('notes', {...data, resource_id: data.resource_type == 'Environment' ? item.id : item.organisation_id})}
		>
			<Input field="text" />
			<DropdownPicker field="resource_type" items={['Environment', 'Organisation']} />
			<Button submitButton> submit </Button>
		</Form>
	)
}
const Note = ({item}) => {
	const {user_name, text, created_at, id, resource_type} = item
	return (
		<div>
			<p>
				Toegevoegd door: {user_name} op {moment(created_at).format('DD-MM-YYYY')} aan {resource_type}
			</p>
			<DeleteButton onDel={() => API.destroy('notes', id)} children={'Delete note'} />
			<p> {text} </p>
		</div>
	)
}

export default Page
