import React, { useState, useEffect, Fragment } from 'react'
import { Button } from 'antd'
import api from 'initializers/api'
import {persist, store, history} from 'index'
import { lastDateSelector } from 'selectors/rev'
// import { useSelector } from 'react-redux'

import * as utils from 'utils/jsutils'
import _ from 'lodash'
import { Link } from 'react-router-dom'
import Chart from 'chart.js'
import moment from 'moment'
import useAsyncEffect from 'hooks/use_async_effect'
import useSelection from 'antd/lib/table/hooks/useSelection'
import { useSelector } from 'react-redux'



const DateGraphContainer = ({data, kind, setKind}) => {
  

  let relKeys = [];
  const hasData = data.length > 0
  const keys = Object.keys(data)
  const lastM = data[keys.length - 1] || {}
  const prevM = data[keys.length - 2] || {}
  const dayLM = dayLastMonth(lastM, data)

  const isDaily = hasData && data[0].date

  const diffDayLastMonth = isDaily ? calcDiff( lastM,dayLM ) : {}
  const diff = calcDiff(lastM, prevM)
  if(hasData) {
    relKeys = Object.keys(data[0]).filter(k => !skipKeys.includes(k))
  }

  

  useEffect(() => {
    let chart;
    if (Object.keys(data).length > 0) {
      const ctx = document.getElementById('myChart').getContext('2d');
      chart = createChart(ctx, data, kind, isDaily)
    }

    return () => {
      chart && chart.destroy()
    }

  }, [kind, data.length])


  return (
      <div className="tableAround">
        <div className="naastElkaar">

        {relKeys.map(k => 
          <StatBlock isActive={kind === k} onClick={() => setKind(k)} diff={diff} data={lastM} 
                     field={k} dayLastMonthDiff={diffDayLastMonth}/> )}

        </div>
        <canvas id="myChart" width="400" height="150"></canvas>

      </div>
    )
}

export default DateGraphContainer;

export const StatBlock = ({ isActive, data = {}, field, onClick, diff = {}, dayLastMonthDiff = {} }) => {
  let num = data[field]
  if(!utils.isPresent(num) || _.isString(num)) return null; // we only deal with numbers, bra
  if (num) num = num.toFixed(2);
  return (<div onClick={onClick}>
    <h3> {num} <span style={{ color: getColor(field) }}> * </span> </h3>
    <p> {field} </p>
    {utils.isUsableNum(diff[field]) && <p style={{ color: diff[field] < 0 ? 'red' : 'green' }}> {diff[field]}%</p>}

    {dayLastMonthDiff[field] && <div>
      <p>Today compared to last month</p>
      <p style={{ color: dayLastMonthDiff[field] < 0 ? 'red' : 'green' }}> {dayLastMonthDiff[field]}%</p>
      </div>
    }

    {isActive && <p style={{color: '#0496ff'}}> ------ </p>}
  </div>)
}


const kindData = {
  'active_users': {
    color: '#FFC48C'
  }
}

const getColor = kind => 
  kindData[kind] ? kindData[kind].color : "#0496FF"


const skipKeys = ['year', 'month']

const createChart = (el, data, kind, isDaily) => {
  const moments = data.map(d => {
    if (isDaily) {
      return moment(d.date).format("DD MMM")
    } else {
      return moment(`${d.year}-${d.month}`).format("MMM - YY")
    }

  })
  const vals = data.map(d => d[kind])
  const color = getColor(kind)
  // let vals2;
  let dataSets = [{
    label: kind,
    data: vals,
    backgroundColor: `${color}20`,
    borderColor: color,
  },]
  return new Chart(el, {
    type: 'line',
    data: {
      labels: moments,
      datasets: dataSets

    },
  })
}

const dayLastMonth = (day, dayData) => {
  const preDate = moment(day.date).add('-1', 'month').format('YYYY-MM-DD')
  const preObj = dayData.filter(dateObj => dateObj.date === preDate)
  return preObj[0]
}



const calcDiff = (current, prev) => {
  const obj = {}
  if (!current || !prev) return obj;
  Object.keys(current).forEach(k => {
    obj[k] = ((current[k] / prev[k] - 1) * 100).toFixed(2)
  })
  return obj;
}




