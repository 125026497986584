const initialState = {
  loading: false,
  mem: 0,
  storage: 0,
  deletedSearch: {},
  deletedTax: [],
}

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case 'START_LOADING':
      return {...state, loading: true}
    case 'STOP_LOADING':
      return {...state, loading: false}
    case 'GET_MEMORY':
      return {...state, mem: action.payload, storage: action.storage}
    case 'SET_DELETED_SEARCH':
      return {...state, deletedSearch: action.search}
    case 'SET_DELETED_TAX':
      return {...state, deletedTax: action.payload}

    default:
      return state
  }
}
