import api from 'initializers/api'
import _ from 'lodash'
import React, {useEffect, useState} from 'react'
import useEnvTable from './use_env_table'
import useWhere from 'hooks/use_where'
import {API} from '@eitje/easy_api'

const Page = () => {

  useEffect(() => {
    API.index("inactive_environments", {localKind: 'environments'})
  }, [])

  const envs = useWhere('environments', {status: 'inactief'})

  const {table} = useEnvTable({items: envs})

  return (
    <div className="topDiv" style={{}}>
      {table}
    </div>
  )
}

export default Page
