import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'initializers/form'
import {Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useParams from 'hooks/use_params'
import useFind from 'hooks/use_find'
import {useTable} from 'components/general/table'
import useExtendOrg from 'hooks/use_extend_org'
import Agreements from 'pages/billing/agreements'
import Infos from 'pages/billing/infos'
import Distributions from 'pages/billing/distributions'
import {API} from '@eitje/easy_api'
import OrgBilling from './billing_show'

const Show = () => {
  const [isStructure, setStructure] = useState(false)
  const {id} = useParams()
  let org = useFind('organisations', id)
  const extendedOrg = useExtendOrg([org])[0]

  return (
    <div style={{marginTop: 100}} className="org-show">
      <Form initialValues={org} onSubmit={data => API.update('organisations', data)}>
        <Input field="billing_note" />
        <DatePicker field="first_billing_date" />
        <Button submitButton>submit</Button>
      </Form>
      <h3> {extendedOrg.name} </h3>

      <Button onClick={() => setStructure(false)} style={{opacity: isStructure ? 0.5 : 1}}>
        Billing
      </Button>
      <Button onClick={() => setStructure(true)} style={{opacity: isStructure ? 1 : 0.5}}>
        Organisatie structuur
      </Button>

      {isStructure && <OrgStructure org={extendedOrg} />}
      {!isStructure && <OrgBilling org={extendedOrg} />}
    </div>
  )
}

const OrgStructure = ({org}) => {
  return (
    <div>
      <h2> Agreements </h2>
      <Agreements items={org.billingAgreements} />

      <h2> Infos </h2>
      <Infos items={org.billingInfos} />

      <h2> Distributions </h2>
      <Distributions items={org.billingDistributions} />
    </div>
  )
}

export default Show
