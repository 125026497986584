import Moment from 'moment'
import {extendMoment} from 'moment-range'

const moment = extendMoment(Moment)

export function makeRange(start, end) {
  const startMoment = moment(start)
  const endMoment = moment(end)
  return moment.range(startMoment, endMoment)
}

const dateFormats = ['YYYY-MM-DD', 'DD-MM-YYYY']

const timeFormats = [
  'YYYY-MM-DDTHH:mm:ss.SSSZ',
  'YYYY-MM-DDTHH:mm:ss.SSSSZ',
  'YYYY-MM-DDTHH:mm:ss',
  'YYYY-MM-DDTHH:mm',
  'YYYY-MM-DDTHH:mmZ',
  'DD-MM-YYYYTHH:mm:ss.SSSZ',
  'DD-MM-YYYYTHH:mm:ss',
  'DD-MM-YYYYTHH:mm',
  'DD-MM-YYYYTHH:mmZ',
  'HH:mm',
]

const formats = [...dateFormats, ...timeFormats]

moment.defaultFormat = formats

moment.fn.isDateTime = function() {
  return timeFormats.includes(this._f)
}

export default moment
