import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover, InfoTooltip} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'
import {flexRender} from '@tanstack/react-table'
import {TranslatedHeader, HeaderTitle, Aggregates} from './index'
import {renderCell} from 'shared/table/index'

const TableHeader = ({header, rows = [], ...rest}) => {
  const context = header.getContext()
  const {tableName} = context.table.options.meta
  const {colSpan, column, idx, id} = header
  let {getCanSort, getIsSorted, getToggleSortingHandler, columnDef} = column
  const canSort = getCanSort()
  const aggFn = column.getAggregationFn()
  let {headerAggregates = true, tooltipTitle, hideTooltip, tooltipElement} = columnDef

  const _rows = rows
    .map(r => (r.getIsExpanded() ? null : utils.exists(r.subRows) ? r.subRows : r))
    .flat()
    .filter(Boolean)
  // count only flat rows

  return (
    <th className="eitje-table-header-cell" key={id} colSpan={colSpan}>
      <div className={`eitje-table-header-cell-content ${id}`} onClick={getToggleSortingHandler()}>
        <div className="eitje-table-header-cell-title-container">
          <h6 className="eitje-table-header-cell-title-text">
            <HeaderInner header={header} />
          </h6>
          {canSort && <SortImg sortDirection={getIsSorted()} />}
        </div>
        {aggFn && headerAggregates && <Aggregates column={column} idx={idx} id={id} fn={aggFn} rows={_rows} />}
      </div>
    </th>
  )
}

const SortImg = ({sortDirection}) => {
  const src = sortDirection == 'asc' ? 'ascending' : sortDirection == 'desc' ? 'descending' : 'noOrder'
  return <img className="eitje-table-header-cell-icon" src={`/images/web/icons/${src}.png`} style={{width: 12}} />
}

const HeaderInner = ({header}) => {
  const context = header.getContext()
  const {customHeaders = []} = context.table.options.meta
  const headerFnName = context.column.columnDef.header.name
  if (customHeaders.includes(header.id) || headerFnName != 'header') return flexRender(header.column.columnDef.header, context)
  return <TranslatedHeader context={context} header={header} />
}

export default TableHeader
