import {useHistory, useLocation} from 'react-router-dom'

const useModalBack = ({simpleBack}) => {
  const hist = useHistory()
  const loc = useLocation()

  const goBack = () => {
    hist.goBack()
  }

  return {goBack}
}

export default useModalBack

const makeUrl = (routeState = {}) => {
  const {pathname, search} = routeState
  if (!pathname) return '/'
  if (!search) return pathname
  return `${pathname}${search}`
}
