import React, {useState, useEffect, useRef, useContext, Fragment} from 'react'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, DatePicker, DropdownPicker, Switch} from '@eitje/form-fields-web'
import moment from 'moment'
import {Button} from 'antd'
import {useSelector} from 'react-redux'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import useParams from 'hooks/use_params'
import {Spin} from 'antd'
import {all, API} from '@eitje/easy_api'
import InputNumber from 'components/form/input_number'
import Form from './form'
import {navigateModal} from 'actions/routing'

const New = () => {
  return (
    <Modal title="New Agreement">
      <Form />
    </Modal>
  )
}

export default New

// afterSubmit={data => navigateModal(`/billing/infos/${data.item.id}`, {replace: true})} 