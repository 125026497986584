import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, DatePicker} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, Select} from 'components/form/types'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/utils'
import {useTable} from 'components/general/table'
import moment, {makeRange} from 'initializers/moment'
import {dateInRange} from 'selectors/records'
import useExtend from 'hooks/use_extend'
export const getYear = date => moment(date).year()
const {MonthPicker} = DatePicker

export const getMonth = date => moment(date).month() + 1 // moment thinks january == 0

export const makeDateObj = date => ({month: getMonth(date), year: getYear(date)})

const fields = ['environment.naam', 'tier', 'amt_users', 'amt', 'modification_amt', 'staffel_amt', 'mollie_mandate']

const Page = () => {
  const [date, setDate] = useState(moment())
  const {year, month} = makeDateObj(date)

  useEffect(() => {
    API.index('billing_costs', {params: {year, month}, refresh: true})
  }, [year, month])

  const range = date.range('month')
  let costs = useSelector(state =>
    dateInRange(state, 'billingCosts', {start_date: range.start, end_date: range.end}, {field: 'billing_date'}),
  )
  costs = costs.filter(c => c.env_names)
  costs = useExtend(costs, 'environment')
  const {table, searchInput} = useTable({items: costs, fields})

  return (
    <div>
      {searchInput}
      <MonthPicker format="MMM YYYY" value={date} onChange={date => setDate(date)} />
      {table}
    </div>
  )
}

export default Page
