import Moment from 'moment'
import {extendMoment, DateRange} from 'moment-range'
import _ from 'lodash'

const moment = extendMoment(Moment)

const formats = ['DD-MM-YYYY', 'YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss.SSSZ']
moment.defaultFormat = formats

export function makeRange(start, end) {
  const startMoment = createMoment(start)
  const endMoment = end ? createMoment(end) : '2050-01-01' // allow open range
  return moment.range(startMoment, endMoment)
}

export function rangeFromStart({start, length = 7, kind = 'day'} = {}) {
  return moment.rangeFromInterval(kind, length - 1, moment(start)) // I find length more logical as in 'total length of range'
}

export function dateArrayFromStart({start, length = 7, kind = 'day'} = {}) {
  const range = rangeFromStart({start, length, kind})
  return Array.from(range.by(kind))
}

export function rangeToDateArray(range, kind = 'day') {
  return Array.from(range.by(kind))
}

export function rangeForMonth({date}) {
  const range = makeRange(date.clone().startOf('month'), date.clone().endOf('month'))
  return range
}

export function dateArrayForMonth({date, kind = 'day'}) {
  const range = rangeForMonth({date})
  return Array.from(range.by(kind))
}

export function createMoment(date) {
  if (date instanceof moment) return date
  if (isTimeString(date)) {
    return moment(date, 'HH:mm')
  } else {
    return moment(date, formats)
  }
}

const timeStringRegex = /^\d{1,2}:\d{2}$/ // accepts both 09:00 and 9:00, but not 9:0

const isTimeString = date => {
  return date?.match && date.match(timeStringRegex)
}

DateRange.prototype.days = function() {
  const end = this.end.clone().endOf('day')
  const start = this.start.clone().startOf('day')
  const diffInDays = end.diff(start, 'days')
  return diffInDays
}

DateRange.prototype.map = function(fn) {
  return [this.start, this.end].map(d => fn(d))
}

moment.fn.formatDateTime = function(format = 'YYYY-MM-DD HH:mm') {
  return this.format(format)
}

moment.fn.prevWeekday = function(n) {
  if (n > this.weekday()) this.subtract(1, 'week')
  return this.weekday(n)
}

moment.fn.nextWeekday = function(n) {
  if (n < this.weekday()) this.add(1, 'week')
  return this.weekday(n)
}

moment.fn.prevMonthday = function(n) {
  if (n > this.date()) this.subtract(1, 'month')
  return this.date(n)
}

moment.fn.nextMonthday = function(n) {
  if (n < this.date()) this.add(1, 'month')
  return this.date(n)
}

export {moment}
