import {API} from '@eitje/easy_api'
import {DropdownPicker, Input, Switch} from '@eitje/form-fields-web'
import {Button} from 'antd'
import useBackgroundEnvs from 'hooks/use_background_envs'
import {ModalForm} from 'initializers/form'
import React from 'react'

const _Form = props => {
  const r = React
  const envs = useBackgroundEnvs()

  const updateBillingInfo = data => {
    if (data['emails']?.split) {
      data['emails'] = data['emails'].split(',').map(e => e.trim())
    }

    return API.update('billing_infos', data)
  }
  return (
    <ModalForm onSubmit={updateBillingInfo} {...props}>
      <Input required field="company_name" />
      <Input field="emails" />
      <Input field="city" />
      <Input field="street" />
      <Input field="house_number" />
      <Input field="postal_code" />
      <Input field="vat_number" />

      <Switch field="ignore_vat" />
      <Switch field="ignore_mollie" />
      <DropdownPicker
        showSearch
        multiple
        style={{width: 200}}
        required={!props.initialValues}
        labelField="naam"
        label="custom envs"
        items={envs}
        field="environment_ids"
      />
      <Button submitButton> submit </Button>
    </ModalForm>
  )
}

export default _Form
