import _ from 'lodash'

const initialState = {
    daily_revs: [],
    weekly_revs: [],
    monthly_revs: [],
}

const dataCalc = (rev) => {
    const newData = rev.map(d => {
        return {
            ...d,
            euro_per_env : d.amt / d.amt_envs,
            user_per_env : d.amt_users / d.amt_envs,
            euro_per_user : d.amt / d.amt_users,
            pct_active_users : ( d.amt_users / d.confirmed_user_count ) * 100
        }
    })

    return newData
    // return newData.map(d => _.mapValues(d, v => _.isNumber(v) ?  Math.round(v) : v ))
}


export default function reduce(state = initialState, action) {
    switch (action.type) {
        case 'RESET_DATA':
        case 'RESET_REV':
            return initialState;

        case 'UPDATE_MONTHLY_REV' :
            return {
                ...state,
                monthly_revs : [...dataCalc(action.rev)]
            }
        case 'UPDATE_DAILY_REV' :
            return {
                ...state,
                daily_revs : [...state.daily_revs, ...dataCalc(action.rev)]
            }
        default : 
            return state
    }
}