import {Button, Modal as AntModal} from 'antd'
import {ModalRedirect} from 'components/routing'
import {DeleteButton} from 'components/ui'
import useModalBack from 'hooks/use_modal_back'
import {t} from 'initializers/i18n'
import React, {forwardRef} from 'react'
import {history} from 'index'

const ModalContext = React.createContext({})
const ModalProvider = ModalContext.Provider

const Modal = props => {
  const {onOk, simpleBack, hideOk, hasCancel, footerBackButton, hideDel, children, noStyle, form, redirectIf, onDel, destructive} = props
  const {goBack} = useModalBack(props)

  if (redirectIf) return <ModalRedirect />

  let condOpts = {}
  if (!onOk && !footerBackButton && !hasCancel && !onDel) condOpts['footer'] = null

  const _onDel = async () => {
    form && form.current && form.current.unTouch()
    const res = await onDel()
    if(res?.ok) goBack();
  }

  const backButton = (
    <Button className="floatL" onClick={history.goBack}>
       {t('back')}
    </Button>
  )

  let footer = [
    footerBackButton && backButton,
    !hideDel && onDel && (
      <DeleteButton danger onDel={_onDel}>
        {t('delete')}
      </DeleteButton>
    ),
    <Button key="back" onClick={goBack}>
      {t('cancel')}
    </Button>,
    !hideOk && onOk && (
      <Button key="submit" type="primary" onClick={onOk}>
        {t('submit')}
      </Button>
    ),
  ].filter(Boolean)

  return (
    <ModalProvider value={{goBack}}>
      <AntModal
        className={noStyle ? '' : 'veedree modalEitje modalOverflow'}
        footer={footer}
        {...condOpts}
        visible
        onCancel={goBack}
        {...props}
      ></AntModal>
    </ModalProvider>
  )
}

export const FullscreenModal = forwardRef((props, ref) => {
  const {children} = props
  return (
    <Modal {...props} className="fullscreenModal" width={'100%'} noStyle bodyStyle={{borderRadius: '16px'}}>
      <div ref={ref} style={{borderRadius: '16px', height: 'calc(100vh - 48px)', overflow: 'scroll'}}>
        {children}
      </div>
    </Modal>
  )
})

export default Modal

export {ModalContext, ModalProvider}
