import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink} from 'components/routing'
import Form from './form'
import Modal, {ModalContext} from 'components/general/modal'
import useParams from 'hooks/use_params'


const Page = () => {
  const {id} = useParams()
  const org = useFind("organisations", id)
  const modal = useContext(ModalContext)
  const destroy = async () => {
    const res = await API.destroy('organisations', id)
    res.ok && modal.goBack()
  }
  return (
      <Modal onDel={() => API.destroy('organisations', id)}>

        <Form initialValues={org}/>
      </Modal>
  )
}

export default Page;
