import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'

const roles = ['admin', 'moderator']

const Page = (props) => {
	const {passRequired} = props
	const modal = useContext(ModalContext)
	return (
			<div>
				<Form onSubmit={data => API.create('admins', data)} afterSubmit={modal.goBack} {...props}>
					<Input required field="email"/>
					<Input required field="name" autocomplete="off"/>
					<Input required={passRequired} field="password" name="password" secure autocomplete="off"/>
					<DropdownPicker allowClear field="role" items={roles}/>
					<Button submitButton> submit </Button>
				</Form>
			</div>
	)
}

export default Page;
