import {logout} from 'actions/auth'
import {getMemory} from 'actions/general'
import {Input} from 'antd'
import {history} from 'index'
import React, {useState} from 'react'
import {useSelector} from 'react-redux'
import {Link} from 'react-router-dom'
const {Search} = Input

const SmallNav = () => {
  const [menuVisible, setMenuVisible] = useState(false)
  const mem = useSelector(state => state.app.mem)
  const storage = useSelector(state => state.app.storage) || 0

  const toggleMenuVisiblity = () => setMenuVisible(state => !state)

  const handleUserSearch = value => {
    if (value) {
      toggleMenuVisiblity()
      history.push(`/search?term=${value}`)
    }
  }

  return (
    <div onClick={e => e.stopPropagation()}>
      <div className="smallNav fRow jBetween aCen">
        <Link to="/home">
          <img className="navLogo" src="/images/eitjeHeader.png" />
        </Link>

        <div id="menuToggle">
          <div onClick={toggleMenuVisiblity}>
            <input type="checkbox" checked={menuVisible} />
            <span />
            <span />
            <span />
          </div>
        </div>
      </div>

      {menuVisible && (
        <div id="fixed" className="smallMenuNav flexBetweenColumn ">
          <div className="smallLinkNav fRow aCen jBetween">
            <Search allowClear size="large" placeholder="Search for User" onSearch={handleUserSearch} style={{width: '100%'}} />
          </div>
          <Link to="/home" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>HQ</p>
              <div className="arrow" />
            </div>
          </Link>
          <Link to="/envs" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>Vestigingen</p>
              <div className="arrow" />
            </div>
          </Link>
          <Link to="/orgs" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>Organisaties</p>
              <div className="arrow" />
            </div>
          </Link>

          <Link to="/billing_agents" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>Billing</p>
              <div className="arrow" />
            </div>
          </Link>
          <Link to="/customer_map" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>eitjeopdekaart</p>
              <div className="arrow" />
            </div>
          </Link>

          <Link to="/records" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>Records</p>
              <div className="arrow" />
            </div>
          </Link>

          <Link to="/inactive_envs" onClick={toggleMenuVisiblity}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>Inactive Envs</p>
              <div className="arrow" />
            </div>
          </Link>

          <Link onClick={logout}>
            <div className="smallLinkNav fRow aCen jBetween">
              <p>Uitloggen</p>
              <div className="arrow" />
            </div>
          </Link>

          <div className="smallLinkNav fRow aCen jCen">
            <div onClick={getMemory} style={{gap: '24px'}} className="fRow jCen aCen">
              <div>
                <p style={{}}>RAM: {mem.toFixed(2)}%</p>
              </div>

              <div>||</div>

              <div>
                <p style={{}}> Storage: {storage.toFixed(2)}% </p>
              </div>
            </div>
          </div>
        </div>

        // <div
        //   onClick={getMemory}
        //   // style={{paddingTop: 24, paddingBottom: 24, borderRadius: 12, marginBottom: 24, gap: '24px'}}
        //   className="bor shadow fRow jCen aCen"
        // >
        //   <div>
        //     <p style={{}}>RAM: {mem.toFixed(2)}%</p>
        //   </div>

        //   <div>||</div>

        //   <div>
        //     <p style={{}}> Storage: {storage.toFixed(2)}% </p>
        //   </div>
        // </div>
        // </div>
      )}
    </div>
  )
}

export default SmallNav
