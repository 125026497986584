import {DatePicker, DropdownPicker} from '@eitje/form-fields-web'
import {Button, Descriptions} from 'antd'
import {store} from 'index'
import Form from 'initializers/form'
import {backend} from '@eitje/easy_api'
import _ from 'lodash'
import moment from 'initializers/moment'
import {timeFormats} from 'initializers/moment'
import React, {useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {prettyOrgsSelector} from 'selectors/records'
import utils from '@eitje/web_utils'

const dropDownProps = {
  containerStyle: {
    display: 'flex',
    alignItems: 'center',
  },
  labelStyle: {
    alignSelf: 'center',
    width: 'fit-content',
    minWidth: '80px',
    whiteSpace: 'nowrap',
  },
  style: {
    // width: '300px',
    minWidth: '150px',
    display: 'flex',
    marginLeft: '18px',
  },
  showSearch: true,
  required: true,
}

const handleSubmit = async data => {
  store.dispatch({type: 'START_LOADING'})
  const res = await backend.post('deleted_records/organisation_records', data)
  if (res.ok) {
    store.dispatch({type: 'SET_DELETED_SEARCH', search: data})
    res.data && store.dispatch({type: 'SET_DELETED_RECORDS', items: res.data.items || []})
  }

  store.dispatch({type: 'STOP_LOADING'})
  return res
}

export const RecordForm = () => {
  const orgs = useSelector(prettyOrgsSelector)
  const ref = useRef()
  const afterChange = (field, value) => {
    if (field === 'organisation_id') {
      const org = orgs.find(org => org.id === value)
      ref.current.setValues({org_name: org.name})
    }
  }

  const tables = useSelector(state => state.app.deletedTax)
  return (
    <Form ref={ref} submitInitialValues onSubmit={data => handleSubmit(data)} afterChange={afterChange}>
      <div className="recordsForm" fieldWrapper>
        <DropdownPicker {...dropDownProps} items={orgs} field={'organisation_id'} label="Organisation" />
        <DropdownPicker {...dropDownProps} items={tables} field={'model'} label="Record Type" />
        <DatePicker {...dropDownProps} field={'start_date'} label="Start Date" />
        <DatePicker {...dropDownProps} field={'end_date'} label="End Date" />
        <Button type="primary" submitButton>
          Search
        </Button>
      </div>
    </Form>
  )
}

export const SearchQuery = () => {
  const query = useSelector(state => state.app.deletedSearch)
  if (_.isEmpty(query)) return <h3>No Search</h3>
  delete query.access_token

  const table = Object.keys(query).map(key => <Descriptions.Item label={_.startCase(key)}>{query[key]}</Descriptions.Item>)

  return (
    <div className="searchQueryBox" style={{padding: '24px 48px'}}>
      <Descriptions size="small" title="Displaying Results For:" bordered>
        {table}
      </Descriptions>
    </div>
  )
}

const likeDateTime = val => {
  if (!utils.exists(val)) return
  return moment(val).isDateTime()
}

export const RecordsTable = () => {
  const deletedRecords = useSelector(state => state.entities.deleted_records) || {}
  const [sortBy, setSortBy] = useState('id')
  const [sortDir, setSortDir] = useState('asc')

  const handleSort = str => {
    sortBy === str ? setSortDir(order => (order === 'asc' ? 'desc' : 'asc')) : setSortDir('asc')
    setSortBy(str)
  }

  const sortFunc = recordObj => {
    if (sortBy === 'deleted_at') return new Date(recordObj.deleted_at)
    if (sortBy === 'date') return moment(recordObj.date).valueOf()
    return recordObj[sortBy]
  }

  const Arrow = (
    <img
      style={{width: '15px', height: '15px', marginLeft: '8px', transform: sortDir === 'asc' ? 'rotate(90deg)' : 'rotate(-90deg)'}}
      alt=""
      src="images/left-arrow.svg"
    />
  )

  if (!deletedRecords || deletedRecords.length === 0)
    return (
      <div style={{textAlign: 'center', marginTop: '60px'}}>
        <h1>No Records</h1>
      </div>
    )

  const records = _.sortBy(deletedRecords, obj => sortFunc(obj))
  const ordered = sortDir === 'asc' ? records : records.reverse()

  const headers = Object.keys(ordered[0]).map(str => (
    <th style={{whiteSpace: 'nowrap', minWidth: '80px'}} onClick={() => handleSort(str)}>
      {_.startCase(str)} {sortBy === str && Arrow}
    </th>
  ))

  const items = ordered.map(record => (
    <tr style={{borderBottom: '1px solid #eee'}}>
      {Object.values(record).map(data => (
        <td style={{height: '50px'}}>{likeDateTime(data) ? moment(data).format('DD-MM-YY HH:mm') : _.toString(data)}</td>
      ))}
    </tr>
  ))

  return (
    <div className="deletedRecords" style={{overflowX: 'scroll'}}>
      <table style={{emptyCells: 'show', overflowX: 'auto'}}>
        <tr>{headers}</tr>
        {items}
      </table>
    </div>
  )
}
