import React, {useState, useEffect, useMemo, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import {Table as BaseTable, useTable} from 'shared/table/index'
import {columns} from './columns'
import {TABLE_NAME, RECORD_TYPE, STICKY_TABLE_FIELDS, FILTERABLE_TABLE_FIELDS} from './constants'
import {ColumnPicker} from 'shared/table/index'

const meta = {tableName: TABLE_NAME, recordType: RECORD_TYPE}

const items = ['signed', 'unsigned', 'all']

const Table = ({data}) => {
  const [filter, setFilter] = useState('all')

  const _data = useMemo(() => {
    if (filter == 'signed') return data.filter(d => d.signed)
    if (filter == 'unsigned') return data.filter(d => !d.signed)
    return data
  }, [filter, data])

  const tableData = useTable({
    columns,
    data: _data,
    stickyFields: STICKY_TABLE_FIELDS,
    meta,
  })

  return (
    <div>
      <DropdownPicker value={filter} style={{width: 100}} items={items} onChange={val => setFilter(val)} />
      <ColumnPicker fields={FILTERABLE_TABLE_FIELDS} name={TABLE_NAME} recordType={RECORD_TYPE} />
      <BaseTable data={tableData} />
    </div>
  )
}

export default Table
