import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink} from 'components/routing'
import {ModalContext} from 'components/general/modal'

const _Form = (props) => {
  const billingAgents = useAll("billingAgents")
  const orgs = useAll("organisations")

  const environments = useAll("environments")
  const modal = useContext(ModalContext)

  return (
      <Form {...props}  afterSubmit={modal.goBack} onSubmit={data => API.create("organisations", data)}>
        <Input required field="name"/>
        <DropdownPicker field="billing_agent_id" items={billingAgents}/>
        <DropdownPicker showSearch required searchField="naam" labelField="naam" multiple field="environment_ids" items={environments}/>
        <Button submitButton> Submit </Button>
      </Form>
  )
}

export default _Form;
