import { createStore, applyMiddleware } from 'redux';
import logger from 'redux-logger';
import reducers from './reducers';
import Reactotron from 'initializers/reactotron.js'
import { REHYDRATE, PURGE, persistCombineReducers, persistStore } from 'redux-persist'
import Localforage from 'localforage'
const config = {
  key: 'eitje-admin',
  storage: Localforage,
  blacklist: ['local']
 }

const persistedReducers = persistCombineReducers(config, reducers)

const rootReducer = (state, action) => {
    return persistedReducers(state, action);
  }


const store = Reactotron.createEnhancer ? createStore(
    rootReducer,
    Reactotron.createEnhancer(),
) : createStore(rootReducer)

  if (process.env.NODE_ENV !== 'production') {
    if (module.hot) {
      module.hot.accept('./reducers', () => {
        store.replaceReducer(rootReducer);
      });
    }
  }

  const persistor = persistStore(store, null, () => { store.getState() } );

  export default () => ({store, persistor})

