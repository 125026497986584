import {Popconfirm} from 'antd'
import React, {Fragment} from 'react'
import _ from 'lodash'
import './styles/selected_area.less'

export const SelectedArea = ({
  items,
  Container = Fragment,
  style,
  renderRemove,
  selectedAreaProps = {},
  Component = EasyRemove,
  placeholder,
  action,
  ...rest
}) => {
  return (
    <div className="selected-area-container">
      {items.length === 0 && placeholder}

      {items.length > 0 && items.map(i => <Component {...rest} renderRemove={renderRemove} item={i} action={action} />)}
    </div>
  )
}

const _renderRemove = item => {
  return item.label || item.naam || item.name
}

export const EasyRemove = ({
  Container = Fragment,
  item,
  disableRemove,
  action = () => {},
  confirmProps = {},
  shouldConfirm,
  renderRemove = _renderRemove,
}) => {
  let condProps = {}
  item = _.isObject(item) ? item : {label: item, value: item}

  if (shouldConfirm && !disableRemove) {
    Container = Popconfirm
    condProps = {...condProps, ...confirmProps, onConfirm: () => action(item.value, item)}
  }
  return (
    <Container {...condProps}>
      <div className="easy-remove" onClick={() => !disableRemove && !shouldConfirm && action(item.value, item)}>
        <div className={`${!disableRemove && 'easyRemoveImg'}`}>
          <h5> {renderRemove(item)} </h5>
        </div>
      </div>
    </Container>
  )
}

export default SelectedArea
