import utils from '@eitje/web_utils'
import {history} from 'index'
import {useLocation, Redirect} from 'react-router-dom'
import _ from 'lodash'
import React from 'react'

export const navigateModal = (path, {search = '', state = {}, replace} = {}) => {
  let loc = history.location
  if (_.isObject(search)) {
    search = new URLSearchParams(search).toString()
  }
  if (replace) loc = loc.state.background
  const args = [`${path}?${search}`, {...state, background: loc}]
  replace ? history.replace(...args) : history.push(...args)
}

export const ModalRedirect = () => {
  let pn = '/'
  const loc = useLocation()
  const bg = loc?.state?.background
  if (bg) {
    pn = bg.pathname
  }

  return <Redirect to={pn} />
}

export function scrollToEnd() {
  const el = document.scrollingElement
  const scrollLeft = el.scrollHeight - el.scrollTop
  return scrollLeft - el.clientHeight
}

export function scrollFromTop() {
  const el = document.scrollingElement
  return el.scrollTop
}

export function getCurrentRoute() {
  return history?.location?.pathname
}
