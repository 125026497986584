import {useFormField, usePicker} from '@eitje/form'
import utils from '@eitje/utils'
import {Button} from 'antd'
import ListSelect from 'components/form/list_select'
import useOutsideClick, {useNewOutsideClick} from 'hooks/use_outside_click'
import {useContainerDimensions} from 'hooks/use_container_dimensions'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {forwardRef, useEffect, useRef, useState} from 'react'
import 'styles/app/components/lists/dropdown_list_select.less'
import {BoxedRow} from 'components/ui'
import {makeRegisteredField, makeField} from '@eitje/form'
import {SelectedArea} from 'components/list_select/selected_area'
import {getNewVal} from 'components/list_select/actions'

const alwaysDefArray = item => utils.alwaysArray(item).filter(i => _.isBoolean(i) || utils.exists(i))

export const DropdownListSelect = forwardRef((props, ref) => {
  let {
    single,
    title = 'edit',
    reverseChildOrder,
    componentProps = {dropdown: true, shadow: true},
    Container = 'div', // not used anymove
    items = [],
    triggerVisible = items.length > 0,
    submitControls,
    resetForm,
    submitForm,
    children,
    placeholder,
    showCount = false,
    Component = BoxedRow,
    onChange,
    listClassName,
    onVisibleChange = _.noop,
    value = [],
    easyRemove,
    containerWidth,
    ...rest
  } = props

  const {valueField = 'id', itemDisabled = _.noop} = props

  value = alwaysDefArray(value)

  const container = useRef(null)
  const [visible, setVisible] = useState(false)
  const notAllSelected = items.length > value.length
  const hide = () => setVisible(false)
  const show = () => setVisible(true)
  useNewOutsideClick({ref: container, parentClass: ['ant-popover'], onClick: hide})
  useEffect(() => {
    onVisibleChange(visible)
  }, [visible])

  const count = value.length

  const {error} = useFormField(props)

  const {width} = useContainerDimensions(container)
  const dropDownWidth = containerWidth || (width > 160 && width)
  const containerClassName = utils.makeCns('eitje-dropdown-list-select-container', error && 'error', count > 0 && 'has-selected-items')
  const listContainerClassName = utils.makeCns(!submitControls && visible && 'eitje-dropdown-list-container', listClassName)

  const _selectAll = () => {
    const validItems = items.filter(i => !itemDisabled(i))

    if (!utils.exists(value)) return onChange(validItems.map(i => i[valueField]))
    return onChange([])
  }
  if (ref) {
    ref.current = {
      hide,
      show,
    }
  }

  const action = (selected, e) => {
    const newVal = single ? selected : getNewVal(value, selected, props)
    const isSelect = newVal.length > value.length
    onChange(newVal, {changed: selected, isSelect})
  }

  const {selectedItems} = usePicker(props)

  return (
    <div className={containerClassName} ref={container}>
      {easyRemove && <SelectedArea items={selectedItems} action={action} />}
      {triggerVisible && (
        <div onClick={e => setVisible(!visible)} className="eitje-dropdown-list-select-trigger-container">
          <Component
            count={value.length}
            placeholder={placeholder}
            className={value.length === 0 ? 'eitje-dropdown-list-select-placeholder' : ''}
            value={value}
            {...componentProps}
            {...rest}
            selectAll={_selectAll}
            title={makeTitle({...props, value})}
            selectedItems={selectedItems}
          />
        </div>
      )}

      {visible && (
        <>
          <div className={listContainerClassName} style={{width: dropDownWidth}}>
            <ListSelect
              single={single}
              checkboxRight
              selectedAtTop={false}
              value={value}
              items={items}
              onChange={onChange}
              {...rest}
              label={null}
            />
            {_.isFunction(children) ? children(setVisible) : children}
          </div>
        </>
      )}

      {submitControls && visible && (
        <div className="fRow aCen jBetween borderBot" style={{padding: 8}}>
          <p
            className="eitje-text-secondary"
            onClick={() => {
              resetForm()
              hide()
            }}
          >
            {t('cancel')}
          </p>
          <Button
            type="primary"
            style={{marginLeft: 12, minWidth: 60}}
            onClick={() => {
              submitForm({callback: hide})
            }}
          >
            {t('submitS')}
          </Button>
        </div>
      )}
    </div>
  )
})

const makeItem = (i, valueField, labelField) => {
  return {[valueField]: i, [labelField]: i}
}

const makeTitle = ({value, items, allSelectedPlaceholder, placeholder, valueField = 'id', title, labelField = 'name'}) => {
  items = items.map(i => (_.isObject(i) ? i : makeItem(i, valueField, labelField)))
  const activeItems = items.filter(i => value.includes(i[valueField]))
  const count = value.length
  if (count == 0) {
    return placeholder || title
  }

  if (allSelectedPlaceholder && count == items.length) {
    return placeholder
  }

  if (count == 1) {
    const actItem = activeItems[0]
    if (!actItem) return null
    return `${actItem[labelField]}`
  } else {
    if (_.isFunction(title)) {
      return title({count})
    }
    return `${title} (${count})`
  }
}

export default DropdownListSelect

export const FormDropdownListSelect = makeField(DropdownListSelect)
