import 'initializers/sentry'
import 'initializers/array'
import React, {useEffect} from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import {Provider} from 'react-redux'
import createStore from './store'
import {Router, Route, Link, withRouter, useHistory, useLocation} from 'react-router-dom'
import {PersistGate} from 'redux-persist/integration/react'
import createBrowserHistory from 'history/createBrowserHistory'
import {AppContainer} from 'react-hot-loader'
import {init as initApi} from 'initializers/easy_api'
import {usePrevious} from '@eitje/react-hooks'
export const {store, persistor} = createStore()
export const history = createBrowserHistory()

initApi()

const TopScroll = ({children}) => {
  const loc = useLocation()
  const prevLoc = usePrevious(loc)
  const hist = useHistory()

  useEffect(() => {
    const isModalRoute = !!loc?.state?.background || !!prevLoc?.state?.background
    store.dispatch({type: 'ROUTE_CHANGE'})
    if (!loc?.state?.scrollTop && !isModalRoute) {
      window.scrollTo({top: 0, behavior: 'smooth'})
    }
  }, [loc])

  return children
}

const render = () => {
  return ReactDOM.render(
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <Router history={history}>
          <TopScroll>
            <App />
          </TopScroll>
        </Router>
      </PersistGate>
    </Provider>,
    document.getElementById('root'),
  )
}

window.onload = () => {
  render()
}

if (module.hot) {
  module.hot.accept('./App', () => {
    render()
  })
}

// I
