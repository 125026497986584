import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {all} from '@eitje/easy_api'

const useAll = (kind) => {
  const items = useSelector(state => all(state, kind ))
  return items;
}

export default useAll