import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {all} from '@eitje/easy_api'
import {useTable} from 'components/general/table'
import {navigateModal} from 'actions/routing'
import {makeRowProps} from 'pages/billing/shared'

const fields = ['id', 'name', 'is_custom', 'billing_info_id']

const Distributions = ({items}) => {
  const defaultItems = useSelector(state => all(state, 'billingDistributions'))
  items = items ?? defaultItems
  const rowProps = makeRowProps('distributions') 
  const {table, searchInput} = useTable({items, fields, rowProps})
  return (
      <div style={{marginTop: 50}}>
        {searchInput}
        {table}
      </div>
  )
}

export default Distributions;
