import {API} from '@eitje/easy_api'
import {useEffect} from 'react'
import useAll from 'hooks/use_all'

const useIndex = (kind, opts = {}) => {
  useEffect(() => {
    API.index(kind, opts)
  }, [])
  const items = useAll(kind)
  return items;

}

export default useIndex