import React, {Component} from 'react'
import {BrowserRouter as Router, Route, Link, Redirect} from 'react-router-dom'
import {hasAuth} from 'actions/auth'
import {useSelector} from 'react-redux'
import useIsAdmin from 'hooks/use_is_admin'
import useIsModerator from 'hooks/use_is_moderator'

export const PrivateRoute = ({component: Component, ...rest}) => (
  <Route {...rest} render={props => (hasAuth() ? <Component {...props} /> : <Redirect to="/login" />)} />
)

export const PublicRoute = ({component: Component, ...rest}) => (
  <Route
    {...rest}
    render={props => {
      return !hasAuth() ? <Component {...props} /> : <Redirect to="/home" />
    }}
  />
)

export const AdminRoute = ({component: Component, ...rest}) => {
  const isAdmin = useIsAdmin()
  return (
    <Route
      {...rest}
      render={props => {
        return isAdmin ? <Component {...props} /> : <Redirect to="/environments" />
      }}
    />
  )
}


export const ModeratorRoute = ({component: Component, ...rest}) => {
  const isModerator = useIsModerator()
  return (
    <Route
      {...rest}
      render={props => {
        return isModerator ? <Component {...props} /> : <Redirect to="/environments" />
      }}
    />
  )
}
