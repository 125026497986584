import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import api from 'initializers/api'
import {persist, store, history} from 'index'
import {lastDateSelector} from 'selectors/rev'
// import { useSelector } from 'react-redux'
import {t} from 'initializers/i18n'
import * as utils from 'utils/jsutils'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import Chart from 'chart.js'
import moment from 'moment'
import useAsyncEffect from 'hooks/use_async_effect'
import useSelection from 'antd/lib/table/hooks/useSelection'
import {useSelector} from 'react-redux'

const skipKeys = ['year', 'month']

// chart container takes an arr of objects and allows you to chart out each of those objects properties
const ChartContainer = ({data = []}) => {
  const hasData = data.length > 0
  const example = data[0] || {}

  const defaultKey = Object.keys(example)[0]
  const [kind, setKind] = useState(defaultKey)
  const relKeys = example ? Object.keys(example).filter(k => !skipKeys.includes(k)) : []
  useEffect(() => {
    let chart
    if (data.length > 0) {
      const ctx = document.getElementById('myChart').getContext('2d')
      chart = createChart(ctx, data, kind)
    }

    return () => {
      chart && chart.destroy()
    }
  }, [data.length, kind])

  if (!hasData) return <p style={{marginTop: 80}}> no data (yet).. </p>
  const lastMonth = data[data.length - 1]

  return (
    <div className="tableAround">
      <div className="naastElkaar">
        {relKeys.map(k => (
          <StatBlock isActive={kind === k} onClick={() => setKind(k)} data={lastMonth} field={k} />
        ))}
      </div>
      <canvas id="myChart" width="400" height="150" />
    </div>
  )
}

export default ChartContainer

export const StatBlock = ({isActive, data = {}, field, onClick}) => {
  let num = data[field]
  if (num) num = Math.round(num)
  const isPct = !!field.match('_pct$')
  const name = isPct ? `${field.replace('_pct', '')}` : field

  return (
    <div onClick={onClick}>
      <h3>
        {num}
        {isPct && '%'}
      </h3>
      <p> {name} </p>

      {isActive && <p style={{color: '#0496ff'}}> ------ </p>}
    </div>
  )
}

const kindData = {
  active_users: {
    color: '#FFC48C',
  },
}

const getColor = kind => (kindData[kind] ? kindData[kind].color : '#0496FF')

const createChart = (el, data, kind) => {
  const moments = data.map(d => {
    return moment(`${d.year}-${d.month}`).format('MMM - YY')
  })
  const vals = data.map(d => d[kind] || 0)
  const color = getColor(kind)
  // let vals2;
  const dataSets = [
    {
      label: kind,
      data: vals,
      backgroundColor: `${color}20`,
      borderColor: color,
    },
  ]
  return new Chart(el, {
    type: 'line',
    data: {
      labels: moments,
      datasets: dataSets,
    },
  })
}
