import * as jsUtils from 'utils/jsutils'
import _ from 'lodash'

const initialState = {
  environments: [],
  organisations: [],
  billing_agents: [],
  facturatieEnvs: [],
  deleted_records: [],

};

export default function reduce(state = initialState, action) {

  switch (action.type) {

    case 'LOGOUT':
      return initialState

    case 'GET_ENTITIES':
      return {
        ...state,
        [action.kind]: action.items.map(i => ({...i}) ) || []
      }

    case 'CREATE_ENTITY':
      return {
        ...state,
        [action.kind]: [...state[action.kind], action.item]
      }

    case 'UPDATE_ENTITY':
    const itemz = [...state[action.kind]]
    const item = {...(itemz.find(i => i.id === action.item.id) || {}), ...action.item}
      return {
        ...state,
        [action.kind]: jsUtils.findAndReplace(itemz, item)

      }

    case 'DELETE_ENTITY':
      const delItems = [...state[action.kind]].filter(i => i.id !== action.id)
      return {
        ...state,
        [action.kind]: delItems
      }


    case 'ADD_ENTITIES':
      let items = [...state[action.kind]]
      action.items.forEach(i => {
        items = jsUtils.findAndReplace(items, i)
      })
      return {
          ...state,
          [action.kind]: items,
      }

    case 'SET_DELETED_RECORDS': return {...state, deleted_records: action.items}


    default:
      return state;
  }
}

