import * as jsUtils from 'utils/jsutils'
import React, {Component} from 'react'
import _ from 'lodash'
import {Input, Button, Select, InputNumber} from 'antd'
const Option = Select.Option;
const TextArea = Input.TextArea;
export class MultiInput extends Component {

  onChange = (val, idx) => {
    const {items} = this.props
    items[idx] = val
    this.props.onChange(items)
  }
  render() {
    const {items, add, field, noAdd} = this.props
    const formItems = items.length > 0 ? items : [""]
    return(
        <div>
          <div>
            {formItems.map(i => <Input  value={i} placeholder={field} className="input mb15"
                                   onChange={(e) => this.onChange(e.target.value, formItems.indexOf(i))}/>  )}
          </div>
        </div>
      )
  }
}

const Label = ({value}) => <p> {value} </p>

export class Form extends Component {

  constructor(props) {
    super(props)
    this.change = this.change.bind(this)
    this.state = {
      formObject: {}
    }
  }

  // default implementation of the 2 required functions

  getFormObject = () => this.state.formObject
  
  afterChange = (formObject, field) => this.setState({formObject: formObject})

  //

  addMultip = (field, val) => {
    let formObject = this.getFormObject()
    let items = [...formObject[field]]
    items = jsUtils.addOrRemove(items, val)
    this.change(field, items)
  }

  change = (field, val) => {
    const obj = this.getFormObject()
    this.afterChange({...obj, [field]: val}, {field})
  }

  parser = (html, {visible} ) => {
    if(visible) { 
      return html;
    } else {
      return null
    }
  }

  eButton = ({field, val, text = field, visible = true, children, ...extraProps}) => {
    return this.parser(
        <Button onClick={() => this.change(field, val)}>
          {children}
        </Button>,
        {visible}
      )
  }

  eButtonSelect = ({options, field}) => {
    let formObject = this.getFormObject()
    const actives = formObject[field]
    debugger
// we're expecting a title prop & an ID prop inside item
  return (
      <div>    
        
        <div className="distribute-buttons">
          {options.map(n => <Button style={{backgroundColor: actives.includes(n.id) ? 'black' : 'white'  }}
                                  className="mb15 button-ixd" 
                                  onClick={() => this.change(field, jsUtils.addOrRemove(actives, n.id))}> {n.title} </Button> )}
        </div>   
      </div>
    )
}

  eTextArea = ({field, ...otherProps}) => {
    const obj = this.getFormObject();
    return <TextArea onChange={(e) => this.change(field, e.target.value)} value={obj[field]} {...otherProps} />
  }

  EInputNumber = ({field, label = field, visible = true, ...extraProps}) => {
    let formObject = this.getFormObject()
    let htmlLabel = label;
    if(!label.$$typeof) {
      htmlLabel = <Label value={label}/>
    }
    return this.parser(
        <div>
          {htmlLabel}
          <InputNumber visible={visible} onChange={(val) => this.change(field, val)} 
                       value={formObject[field]} {...extraProps}/>
        </div>
      , {visible} )
  }

  EInput = ({field, secure = false, label = field, withLabel = false, ...extraProps}) => {
    const {change, renderMultiInput} = this
    const formObject = this.getFormObject()
    const val = formObject[field] || ""

    let htmlLabel = label;
    if(!label || !label.$$typeof) {
      htmlLabel = <Label value={label}/>
    }
    if(_.isArray(val)) return renderMultiInput(field, val);


    return (
        <div className="mb15 nesting-distribute-inputs"> 
        {withLabel && htmlLabel}
          {secure ? <Input.Password  value={String(val)} placeholder={field} className="input"
                 onChange={(e) => change(field, e.target.value)} {...extraProps} />
            : <Input value={String(val)} placeholder={field} className="input"
                 onChange={(e) => change(field, e.target.value)} {...extraProps} />}
         </div>
      )
  }

  eMultiSelect = ({options, field, noAdd, displayField = 'title', valField = 'id', ...extraProps}) => {

    

    let formObject = this.getFormObject()
    let items = formObject[field]
    if(!items || items.length == 0) items = [""];

    const onChange = (val, idx) => {
      
      items[idx] = val
      this.change(field, items)
    }

    return (
        <div>
          {items.map(i => 
            <Select style={{ width: '100%'}}
                    className="mb30"
                    onChange={(val) => onChange(val, items.indexOf(i))} 
                    value={i}
                    defaultValue="Kies cat"
                    {...extraProps}
                    >
                    {options.map(opt => 
                        <Option value={opt[valField]}> 
                          {opt[displayField]}
                        </Option>
                      )}
            </Select>)}
          {!noAdd && <Button onClick={() => this.change(field, [...items, ""] )}> extra </Button>}
        </div>
      )
  }



  ESelect = ({options, field, showSearch, disabledOpts = [],  displayField = 'title', valField = 'id', ...extraProps}) => {
    const {change} = this
    let formObject = this.getFormObject()
    let condOpts = {}
    if(showSearch) {
      condOpts['filterOption'] = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      condOpts['optionFilterProp'] = 'children'
    }

    return( <Select style={{ width: '100%', marginBottom: 15,}}
                    onChange={(val) => change(field, val)} 
                    value={formObject[field]}

                    mode="multiple"
                    {...condOpts}
                    {...extraProps}

                    >
                    {options.map(opt => 
                        <Option disabled={disabledOpts.includes(opt[valField]) }value={opt[valField]}> 
                        {opt[displayField]}
                        </Option>
                      )}

        

        </Select>)
  }




  renderMultiInput = (field, items) => {
    const {change} = this
    return (
        <MultiInput field={field} add={() => change(field, [...items, ""] )} items={items} 
                    onChange={(val) => change(field, val)}/>
      )
    // ['uva', 'barla']
  
  }




}


  


 

