import React, {useState, useEffect, useRef, useContext, Fragment} from 'react'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, DatePicker, DropdownPicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {Button} from 'antd'
import {useSelector} from 'react-redux'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import useParams from 'hooks/use_params'
import {Spin} from 'antd'
import Form from './form'
import {all, API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'

const Page = () => {
  const {id} = useParams()
  return (
    <Modal title={`Billing distribution #${id}`}>
      <Content id={id}/> 
    </Modal>
  )
}

const Content = ({id}) => {
  
  const {data} = useGet(`billing_distributions/${id}`)
  if(!utils.exists(data))  return <Spin loading/>
  const {item} = data
  
  return (
      <div>
        <p> envs die hier bij horen: {item.env_names} </p>
        <Form initialValues={item}/>
      </div>
    )
}

export default Page
