import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'initializers/form'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/web_utils'
import GooglePlaces from 'components/google_places'
import {Switch, Input, DropdownPicker, DatePicker} from '@eitje/form-fields-web'
import {ModalContext} from 'components/general/modal'
import {useMergeState} from '@eitje/react-hooks'
import {createMoment} from 'helpers/date'

const defaultParams = {send_invite_mail: true, status: 'actief'}

const parseGPlaces = results => {
  if (!results[0]) {
    return {}
  }
  var loc = results[0].geometry.location
  var addr_component = results[0].address_components.filter(r => r.types.includes('locality'))[0]
  return {
    city: addr_component.long_name,
    formatted_addr: results[0].formatted_address,
    placeId: results[0].place_id,
    lat: loc.lat(),
    lng: loc.lng(),
  }
}

const _Form = ({initialValues = {}}) => {
  const {signing_status} = initialValues
  const [formState, setState] = useMergeState(initialValues)
  const [info, setInfo] = useState({})
  const orgs = useAll('organisations')
  const settingParams = {settings: {info}}
  const modal = useContext(ModalContext)
  const {first_billing_date} = formState

  return (
    <Form
      mirrorFields={['first_billing_date']}
      setState={setState}
      afterSubmit={modal.goBack}
      onSubmit={data => API.create('environments', data, {extraParams: settingParams})}
      initialValues={{...defaultParams, ...initialValues}}
    >
      <Input required field="naam" placeholder="Naam van vestiging" />

      {!utils.exists(initialValues) && (
        <div fieldWrapper>
          <Input required label="e-mail van administrator" type="email" field="email" placeholder="Emailadres van Admin" />
          <Switch label="Stuur uitnodigingsmail naar administrator" field="send_invite_mail" />
        </div>
      )}

      <DropdownPicker
        allowClear
        label="behoort tot organisatie"
        showSearch
        placeholder="Koppel aan organisaties"
        field="organisation_id"
        items={orgs}
      />

      <DropdownPicker label="status van vestiging" field="status" items={['actief', 'inactief', 'eitje']} />

      <p>Locatie van vestiging</p>
      <GooglePlaces address={initialValues.place} onSucc={results => setInfo(parseGPlaces(results))} />
      <DatePicker field="first_billing_date" />
      {first_billing_date && <FirstBillingDate date={first_billing_date} />}
      {!!signing_status && <p> Signing: {signing_status} </p>}

      <div fieldWrapper className="endFlex">
        <Button submitButton className="selfEnd orange">
          Opslaan
        </Button>
      </div>
    </Form>
  )
}

const FirstBillingDate = ({date}) => {
  return (
    <div>
      <p> dat is: </p>
      <p> {createMoment(date).fromNow()} </p>
    </div>
  )
}

export default _Form
