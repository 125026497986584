import {useEffect} from 'react'
import utils from '@eitje/web_utils'

const parentCheck = (classNames = [], event) => {
  if (event?.target?.parentElement) {
    const parentsClass = event?.target?.parentElement?.offsetParent?.classList || []
    return classNames.some(c => [...parentsClass].includes(c))
  }
  return false
}

function useOutsideClick(ref, onClick, {classNames = [], checkParents = true} = {}) {
  useEffect(() => {
    function handleClickOutside(event) {
      const refClicked = ref.current && ref.current.contains(event.target)
      const classNameClicked = classNames.some(c => [...event.target.classList].includes(c))
      const offsetClicked = checkParents && parentCheck(classNames, event)
      if (!refClicked && !classNameClicked && !offsetClicked) {
        onClick()
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [ref])
}

export default useOutsideClick

export const useNewOutsideClick = ({onClick, parentClass, ref}) => {
  parentClass = utils.alwaysArray(parentClass)
  const _handleClickOutside = e => handleClickOutside(e, onClick, parentClass, ref)
  useEffect(() => {
    document.addEventListener('mousedown', _handleClickOutside)
    return () => {
      document.removeEventListener('mousedown', _handleClickOutside)
    }
  }, [])
}

const handleClickOutside = (event, onClick, matchClasses, ref) => {
  const {target} = event
  let element = target
  let match
  while (!match && element) {
    const classList = Array.from(element.classList)
    match = utils.intersects(classList, matchClasses) || ref?.current?.contains(event.target)
    element = element?.parentElement
  }
  if (!match) onClick()
}
