import * as Sentry from '@sentry/react'

if (process.env.NODE_ENV == 'production') {
  Sentry.init({
    dsn: 'https://de9e25ef37e74f5b95240d1af8834e89@o360459.ingest.sentry.io/6591018',

    ignoreErrors: [
      'ResizeObserver loop limit exceeded',
      '{"code":43,"StatusCode":401,"message":"WS failed with code 43 and reason - JWTAuth error',
    ],
    release: process.env.REACT_APP_VERSION || '3.8.0',
  })
}
