import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {all} from '@eitje/easy_api'
import {useTable} from 'components/general/table'
import {navigateModal} from 'actions/routing'
import {extendSelector} from 'selectors/records'
import {history} from 'index'
import useExtendOrg from 'hooks/use_extend_org'
import {useBoolState} from '@eitje/react-hooks'

const stickyFields = ['id', 'name']
const fields = [
  'id',
  'name',
  'amtEnvs',
  'billingInfo.mollie_mandate',
  'has_custom',
  'billingAgreement.price_per_user',
  'billingInfo.ignore_mollie',
  'billingAgreement.discount_amt',
  'billingAgreement.discount_pct',
  'billingInfos.length',
  'billingAgreements.length',
  'billingDistributions.length',
  'billingAgreement.reduction_amt_per_user',
]

const Orgs = () => {
  const [ignoreFilter, toggleIgnoreFilter] = useBoolState()
  let orgs = useSelector(state => all(state, 'organisations')) || []
  orgs = useExtendOrg(orgs)

  if (ignoreFilter) {
    orgs = orgs.filter(o => o.billingInfo?.ignore_mollie)
  }

  const rowProps = {
    onClick: i => history.push(`/billing/orgs/${i.id}`),
  }
  const {table, searchInput, columnPicker} = useTable({items: orgs, showIdx: true, name: 'orgTable', stickyFields, fields, rowProps})
  return (
    <div style={{marginTop: 50}}>
      <Button onClick={toggleIgnoreFilter}> {ignoreFilter ? 'show all' : 'filter ignored'} </Button>
      {searchInput} {columnPicker} {table}
    </div>
  )
}

export default Orgs
