import {setup, initApi} from '@eitje/easy_api'
import {store} from 'index'
import {t} from 'initializers/i18n'
import {url} from 'initializers/api'
import Reactotron from 'reactotron-react-js'
import {getToken, logout} from 'actions/auth'
import utils from '@eitje/web_utils'
let backend

const indexUrls = {
  pending_invoices: 'billing_invoices/pending_invoices',
  cost_distributions: 'billing_distributions/costs',
  inactive_environments: 'environments/inactive',
}

const init = () => {
  setup({t, store, alert: utils.errNotif, baseURL: url, logout, formErrors: false, indexUrls, enrichRecords, getRefreshToken: getToken})
  backend = initApi()
  backend.addMonitor(Reactotron.apisauce)
}

const enrichRecords = (ents, key) => {
  const state = store.getState()
  const records = ents[key]
  if (!records) return null
  if (key == 'billingActivities') {
    return records.map(extendAct)
  }
  if (key.includes('billing')) {
    return records.map(r => (!r.environment_ids ? r : {...r, is_custom: r.environment_ids.length > 0}))
  }
}

const extendAct = r => {
  const total_user_count = r.confirmed_user_count + r.unconfirmed_user_count
  return {...r, total_user_count, pct_active_users: Math.round((r.activity_user_count / total_user_count) * 100)}
}

export {init, backend}
