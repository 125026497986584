import _ from 'lodash'
import {useState} from 'react'

export function isNumeric(num){
  return !isNaN(num) && !_.isBoolean(num)
}

const getValue = (item, {sortField, nested}) => {
  const val = nested ? item[sortField].value : item[sortField]
  if(isNumeric(val)) return parseFloat(val)
  if (_.isString(val)) return val.toLowerCase()
  return val
}

const isBool = item => getValue(item, )

export const sortItems = (items, props = {}) => {
  const {sortField, sortFuncs = {}, asc} = props
  if(items.length === 0) return items;
  let _items = items
  if (sortField) {
    const order = asc ? 'asc' : 'desc'
    if (sortFuncs[sortField]) return sortFuncs[sortField](items)

    if(_.isBoolean(getValue( items[0], props ))) { // bools should be sortable on true/false
      
      return _.orderBy(items, item => getValue(item, props), order)
    }

    const falsyItems = items.filter(i => !getValue(i, props))
    const truthyItems = items.filter(i => !!getValue(i, props))


    _items = [..._.orderBy(truthyItems, item => getValue(item, props), order), ...falsyItems]
  }
  return _items
}

const useSort = ({items, fields, defSortField, defOrder, ...rest}) => {
  const [sortField, setSortField] = useState(defSortField)
  const [asc, setAsc] = useState(defOrder === 'desc' ? false : true)

  const changeSort = newField => {
    if (sortField === newField) {
      setAsc(!asc)
      return
    }
    setSortField(newField)
    setAsc(true)
  }

  const sortedItems = fields.includes(sortField) ? sortItems(items, {sortField, asc, ...rest}) : items

  return {changeSort, sortedItems, asc, sortField}
}

export default useSort
