import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API, useGet} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {history} from 'index'
import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import {Tooltip} from 'components/ui'
import Dropdown from 'components/dropdown'
import {useRouteMatch, Switch} from 'react-router-dom'

const fields = ['full_name', 'email', 'envs', 'active_in_period', 'roles']
const searchProps = {
	searchField: 'full_name',
}

export const rowProps = {
	onClick: i => history.push(`/search?term=${i.full_name}`),
}

const renderCell = (item, field, idx) => {
	const val = _.get(item, field)
	switch (field) {
		case 'envs':
			return <Envs envs={val} />
	}
}

const Envs = ({envs = []}) => {
	const Container = envs.length > 1 ? Tooltip : Fragment
	return (
		<Container title={envs.join(', ')}>
			<p> {envs.length} </p>
		</Container>
	)
}

const Page = ({item, ...rest}) => {
	const {id, invoices = []} = item
	const {data = {}} = useGet(`environments/${id}/users`)
	const {items} = data
	const {table, searchInput} = useTable({showTotals: false, fields, rowProps, searchProps, items, renderCell})

	return (
		<div>
			{searchInput}
			{invoices.length > 0 && <DownloadInvoices {...rest} invoices={invoices} />}
			{table}
		</div>
	)
}

const DownloadInvoices = ({item, invoices, ...rest}) => {
	const elements = invoices.map(i => <InvoiceLink {...rest} {...i} />)
	return (
		<Dropdown elements={elements}>
			<p> Download invoice </p>
		</Dropdown>
	)
}

const InvoiceLink = ({id, baseUrl, year_month}) => {
	return <ModalLink to={`${baseUrl}/invoices/${id}`}>{year_month}</ModalLink>
}

export default Page
