import {usePicker} from '@eitje/form'
import utils from '@eitje/web_utils'
import useSearch from 'hooks/use_search'
import useToggleState from 'hooks/use_toggle_state'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment, useState} from 'react'
import {crazyFilter} from 'selectors/records'
import ListSelect from 'components/list_select/list_select'
import {Checkbox} from 'components/list_select/picker_item'
import {EasyRemove, SelectedArea} from 'components/list_select/selected_area'
import {makeRegisteredField} from '@eitje/form'

const FormListSelect = ListSelect // makeRegisteredField(ListSelect)

// we're not gonna bump form..

export {ListSelect, FormListSelect, Checkbox, EasyRemove, SelectedArea}

export default ListSelect
