import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {Switch, Redirect} from 'react-router-dom'
import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import Header from 'cores/trial_conversion/components/header'
import {foregroundRoutes as routes} from 'cores/trial_conversion/routes'

const TrialConversionWrapper = () => {
  return (
    <div style={{marginTop: 70}}>
      <Header />
      <Routes />
    </div>
  )
}

const Routes = () => <Switch>{routes}</Switch>

export default TrialConversionWrapper
