import React from 'react'
import ReactDOM from 'react-dom'
import {Form} from 'inheritables/Form'
import {Button} from 'antd'
import {login} from 'actions/auth'
import 'styles/login.css'
import 'styles/app.css'
import {Link} from 'react-router-dom'
import {t} from 'initializers/i18n'
import Slider from 'components/slider'

const images = ['/images/planningBG.png', '/images/planningBG.png', '/images/planningBG.png', '/images/planningBG.png']

const Item1 = props => {
  return (
    <div className="fCol jCen aCen">
      <div>
        <img className="phones rooster" src="/images/rooster.png" />
        <img className="phones uren" src="/images/uren.png" />
      </div>

      <div className="rightTextField">
        <p className="signUpText">Your own common room</p>
        <p className="signUpSub">
          Make your team feel right at home with eitje. All of the essentials for your teammembers packed in one app.
        </p>
      </div>
    </div>
  )
}

const Item2 = props => {
  return (
    <div className="fCol jCen aCen">
      <div>
        <img className="phones rooster" src="/images/rooster.png" />
      </div>

      <div className="rightTextField">
        <p className="signUpText">Gekkkkkie</p>
        <p className="signUpSub">LEkkker vreemde gek</p>
      </div>
    </div>
  )
}

class Login extends Form {
  render() {
    const {EInput} = this
    const {email, password} = this.state.formObject

    return (
      <div className="fRowM">
        <div className="leftField fCol aSta jBetween" style={{}}>
          <div>
            <img className="logoLogin" src="/images/eitjeHeader.png" />
          </div>

          <div className="fCol loginField">
            <p className="welcomeText">Fire up your team 🔥</p>
            <p className="welcomeSubText">Enter your credentials and start to witness the real power of your team.</p>
            <EInput placeholder="E-mail" type="email" onKeyPress={event => event.key == 'Enter' && login(email, password)} field="email" />
            <EInput secure placeholder="Wachtwoord" onKeyPress={event => event.key == 'Enter' && login(email, password)} field="password" />
            <Button className="blue" onClick={() => login(email, password)}>
              Inloggen
            </Button>
          </div>

          <div>
            <p />
          </div>
        </div>

        <div className="rightField fCol jCen aCen">
          <Slider slides={images} autoPlay={5} />
        </div>
      </div>
    )
  }
}

export default Login
