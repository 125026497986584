import {TableHeader} from 'components/table'
import {backend} from 'initializers/easy_api'
import QueryString from 'query-string'
import React, {useEffect, useState} from 'react'
import {useSelector} from 'react-redux'
import {useHistory} from 'react-router-dom'
import useAll from 'hooks/use_all'

const User = ({item, envs}) => {
  const history = useHistory()
  const {id, full_name, telefoonnummer, email, environments, vloer_code} = item
  const userData = [id, full_name, telefoonnummer, email, environments.map(e => e.naam), vloer_code]

  return (
    <tr
      style={{borderBottom: '1px solid #eee'}}
      onClick={() =>
        history.push({
          pathname: `/users/${item.id}`,
          state: {user: item, fun: 'fun'},
        })
      }
    >
      {userData.map((str, index) => (
        <td className="noLeftPad" style={{paddingLeft: index === 0 && '8px'}}>
          {' '}
          {str}{' '}
        </td>
      ))}
    </tr>
  )
}

const Search = ({location}) => {
  const envs = useAll('environments')
  const prms = QueryString.parse(location.search)
  const term = prms.term
  const [users, setUsers] = useState([])
  useEffect(() => {
    const fetchData = async () => {
      const res = await backend.get(`users/search`, {term: term})
      if (res.ok) {
        setUsers(res.data.items)
      }
    }
    fetchData()
  }, [term])

  return (
    <div className="topDiv" style={{overflowX: 'scroll'}}>
      <table style={{emptyCells: 'show', overflowX: 'auto'}} cellpadding="24">
        <TableHeader fields={['ID', 'Naam', 'Tel', 'E-mail', 'Vestigingen', 'VloerID']} />

        {users.map(u => (
          <User item={u} />
        ))}
      </table>
    </div>
  )
}

export default Search

// table {
//   border-collapse: collapse;
//   border-spacing: 0;
//   width: ${Dimensions.get('window').width}px;
//   empty-cells: show;
//   overflow-x: auto;
// }
