import React, {Fragment, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import {Button} from 'antd'
import useSearch, {SearchEmpty} from 'hooks/use_search'
import Table from 'components/general/table'
import {DropdownPicker} from '@eitje/form-fields-web'
// import DropdownListSelect from 'components/form/dropdown_list_select'
import {store} from 'index'
import {t} from 'initializers/i18n'
import utils from '@eitje/web_utils'

const useTable = ({items = [], name, fields = [], stickyFields = [], searchProps = {}, rowProps = {}, tableProps = {}, ...rest}) => {
  let activeFields = useSelector(state => state.filters[name])
  activeFields = !utils.exists(activeFields) ? fields : fields.filter(f => activeFields.includes(f)) // keep sort order of fields order
  const {filteredItems, searchInput, search, setSearch} = useSearch(items, searchProps)

  const table = (
    <Table
      {...tableProps}
      renderEmpty={search && <SearchEmpty action={() => setSearch('')} />}
      rowProps={rowProps}
      fields={activeFields}
      items={filteredItems}
      {...rest}
    />
  )

  const columnItems = fields.filter(f => !stickyFields.includes(f)).map(i => ({id: i, name: t(`table.${i}`, t(`records.${i}`))}))

  const columnPicker = (
    <DropdownPicker
      multiple
      labelField="name"
      value={activeFields}
      onChange={val => store.dispatch({name, type: 'SET_FILTER', value: val})}
      items={columnItems}
    />
  )

  return {searchInput, table, columnPicker}
}

export default useTable

const ImageButton = ({onClick, title}) => {
  return (
    <Button className="fRow aCen jSta" style={{width: 180}} onClick={onClick}>
      <img src="/images/filterTeams.png" style={{width: 12, marginTop: -2, marginRight: 8}} />
      {t('editColumns')}
    </Button>
  )
}
