import moment from 'moment'
import {buildColumn} from 'shared/table/index'
const BoolCell = item => (item.getValue() ? 'yes' : 'no')
const DateCell = item => moment(item.getValue()).format('D MMMM YYYY HH:mm')
const BeautyDateCell = item => moment(item.getValue()).format('D MMMM YYYY')
export const columns = [
  'name',
  'id',
  {accessorKey: 'created_at', cell: DateCell},
  'user_count',
  {accessorKey: 'signed', cell: BoolCell},
  {accessorKey: 'first_billing_date', cell: BeautyDateCell},
  'planning_shift_count',
  'time_shift_count',
  'token_count',
  'topic_count',
  'post_count',
  'skill_set_count',
  'event_count',
].map(buildColumn)
