import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button, DatePicker as AntDatePicker} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import useGet from 'hooks/use_get'
import Table from './table'

const {MonthPicker} = AntDatePicker

const Page = () => {
	const initialDate = moment()
		.subtract(3, 'months')
		.startOf('month')
	const [date, setDate] = useState(initialDate)

	const params = {month: utils.getMonth(date), year: date.year()}

	const {data = {}} = useGet(`trial_conversion/organisations`, {params, watch: date.format('YYYY-MM')})
	const {items = []} = data

	const orgs = useAll('organisations')

	const _items = items.map(i => {
		const org = orgs.find(o => o.id == i.id)
		if (!org) return i
		return {...i, ...org}
	})

	return (
		<div>
			<MonthPicker allowClear={false} value={date} onChange={date => setDate(date)} />
			<Table data={_items} />
		</div>
	)
}

export default Page
