import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useAll from 'hooks/use_all'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'

const fields = ['name', 'env_names']

const rowProps = {
  onClick: i => navigateModal(`organisations/${i.id}`)
}

const Index = () => {
  let items = useAll("organisations")
  items = useExtend(items, "environments").map(i => ({...i, env_names: i.environments.map(n => n.naam)   }) )
  const {table, searchInput} = useTable({items, fields, rowProps, showTotals: false})

  return (
      <div className="topDiv">
      <ModalLinkBtn text="New org" to="/organisations/new"/>
        {searchInput}
        {table}
        
      </div>
  )
}

export default Index;
