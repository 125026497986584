import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, DatePicker} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, Select} from 'components/form/types'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/utils'
import {useTable} from 'components/general/table'
import moment, {makeRange} from 'initializers/moment'
import {dateInRange} from 'selectors/records'
import useExtend from 'hooks/use_extend'
const {MonthPicker} = DatePicker

export const getYear = date => moment(date).year()

export const getMonth = date => moment(date).month() + 1 // moment thinks january == 0

export const makeDateObj = date => ({month: getMonth(date), year: getYear(date)})

const fields = [
  'name',
  'mollie_mandate',
  'tier',
  'amt_users',
  'price_per_user',
  'user_tier_count',
  'amt',
  'reduction_amt_per_user',
  'staffel_amt',
  'id',
  'cost_ids',
  'env_names',
  'modification_amt',
]

const Page = () => {
  const [date, setDate] = useState(moment().subtract(1, 'month'))
  const {year, month} = makeDateObj(date)

  useEffect(() => {
    API.index('cost_distributions', {params: {year, month}, refresh: true})
  }, [year, month])
  const range = date.range('month')
  let costs = useSelector(state => dateInRange(state, 'costDistributions', {start_date: range.start, end_date: range.end}))
  costs = costs.filter(c => c.env_names)
  const {table, searchInput} = useTable({items: costs, fields})
  return (
    <div>
      <h3> Totaal: {costs.length} </h3>
      {searchInput}
      <MonthPicker format="MMM YYYY" value={date} onChange={date => setDate(date)} />
      {table}
    </div>
  )
}

export default Page
