import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {persist, store, history} from 'index'

export async function login(email, password) {
  const body = {email, password, grant_type: 'password', scope: 'admin'}
  const resp = await api.post('oauth/token', body)
  if(!resp.problem) {
    const {data} = resp
    const obj = {
        token: data.access_token, expires_at: data.created_at + data.expires_in,
        user: data.user, refresh_token: data.refresh_token 
    }
    await store.dispatch({type: 'AUTH_SUCCESS', ...obj})
    history.push("/home")
    return obj 
  } else {
    jsUtils.errNotif("Let op", "Deze email/wachtwoord combinatie is onjuist")
    // 
  }
}

export async function signUp(email, password, first_name, last_name, history, type, store) {
  const body = {email, password, first_name, last_name,
                password_confirmation: password }
  const resp = await api.post(`users`, {user: body} )
  if(!resp.problem) {
    await login() 
    //await call(login, email, password, null )

  }
}

export function getToken() {
  const state = store.getState();
  return state.auth.token
}


export function hasAuth() {
  return !!getToken()
}


export async function logout() {
  store.dispatch({type: 'LOGOUT'})
  store.dispatch({type: 'RESET_RECORDS'})
  history && history.replace("/login")
}


