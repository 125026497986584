import {DeleteOutlined} from '@ant-design/icons'
import {Button, Descriptions} from 'antd'
import api from 'initializers/api'
import _ from 'lodash'
import React from 'react'
import {useLocation} from 'react-router-dom'
import {Input} from '@eitje/form-fields-web'
import Form from 'initializers/form'
import * as jsUtils from 'utils/jsutils'
import {backend} from 'initializers/easy_api'
import useIsModerator from 'hooks/use_is_moderator'

const delEmpType = async item => {
  const res = await api.delete(`user_employment_types/${item.id}`)
  if (res.ok) {
    jsUtils.openNotif('Hij is verwijderd', 'warning', 'Maar de pagina ververst zichzelf niet!')
  }
}

const presentArray = arr => arr.join(', ')

const PresentObj = ({obj}) => {
  if (!obj) return null
  const keys = Object.keys(obj)

  return (
    <Descriptions>
      {keys.map(key => (
        <Descriptions.Item labelStyle={{maxWidth: '250px'}} label={_.startCase(key)} span={getSpan(key)}>
          {Array.isArray(obj[key]) ? presentArray(obj[key]) : typeof obj[key] === 'object' ? <PresentObj obj={obj[key]} /> : obj[key]}
        </Descriptions.Item>
      ))}
    </Descriptions>
  )
}

const UserEmpType = ({item}) => {
  const isMod = useIsModerator()
  const cb = () => delEmpType(item)
  return (
    <div style={{marginBottom: 20, display: 'flex', alignItems: 'center', gap: '12px'}}>
      <PresentObj obj={item} />
      {isMod && (
        <Button
          type={'primary'}
          danger
          onClick={() => jsUtils.showConfirm('weet je zeker', 'dat je wil verwijderen', cb)}
          icon={<DeleteOutlined />}
        />
      )}
    </div>
  )
}

const bigSpan = ['full_name', 'email', 'telefoonnummer', 'team_ids']
const recordKeys = ['teams', 'environments']

const getSpan = str => (bigSpan.includes(str) ? '3' : '3')

const makeItem = val => {
  if (Array.isArray(val)) return val.join(', ')
  if (_.isObject(val)) return JSON.stringify(val)
  return val
}

const submitContent = 'Je past het e-mailadres van een gebruiker aan!'

const present = (user, key) => {
  const val = user[key]
  if (recordKeys.includes(key)) return <PresentRecord value={val} />
  return Array.isArray(val) ? presentArray(val) : typeof val === 'object' ? <PresentObj obj={val} /> : val
}

const PresentRecord = ({value}) => {
  return JSON.stringify(value)
    .replace('{', '')
    .replace('[', '')
    .replace(']', '')
    .replace('}', '')
}
const Page = () => {
  const loc = useLocation()
  const isMod = useIsModerator()
  const state = loc.state || {}
  const {user} = state
  const {user_employment_types, ..._user} = user

  const userInformation = Object.keys(_user)
  const components = userInformation.map(key => (
    <Descriptions.Item labelStyle={{maxWidth: '250px'}} span={getSpan(key)} label={_.startCase(key)}>
      {present(user, key)}
    </Descriptions.Item>
  ))

  return (
    <div className="userInfo" style={{marginTop: 70, padding: '24px 48px'}}>
      <Descriptions size="small" title="User Information" bordered>
        {components}
        <Descriptions.Item label={'User employment types'}>
          {user_employment_types.map(u => (
            <UserEmpType item={u} />
          ))}
        </Descriptions.Item>
      </Descriptions>
      {isMod && (
        <Form
          confirmSubmit
          onSubmit={data => jsUtils.showConfirm('Let op', submitContent, () => backend.put(`users/${user.id}`, data))}
          initialValues={user}
        >
          <Input field="email" />
          <Button submitButton> submit </Button>
        </Form>
      )}
    </div>
  )
}

export default Page
