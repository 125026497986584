import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API, useWhere, useGet} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import useParams from 'hooks/use_params'

const fields = ['id', 'full_name', 'weight']

const renderCell = (item, field, idx) => {
	const val = _.get(item, field)
	switch (field) {
		case 'environment_ids':
			return <Envs envIds={val} />
	}
}
const searchProps = {searchField: 'full_name'}

const Invoice = () => {
	const {id} = useParams()
	const {loading, data} = useGet(`billing_invoices/${id}`)
	const {item = {}} = data
	const {user_overview = []} = item
	const {table, searchInput} = useTable({showTotals: false, fields, items: user_overview, searchProps, renderCell})
	return (
		<div>
			{searchInput}
			{loading ? <p> loading... </p> : table}
		</div>
	)
}

const Envs = ({envIds}) => {
	const envs = useWhere('environments', envIds)
	return <p> {envs.map(e => e.naam)} </p>
}

export default Invoice
