import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import {useIncrementState, useAsyncEffect} from '@eitje/react-hooks'
import {backend} from 'initializers/easy_api'

export const useGet = (endPoint, {params = {}, watch, skipInitial} = {}) => {
  const [retries, retry] = useIncrementState()
  const [data, setData] = useState({})
  const [error, setError] = useState()
  const [loading, setLoading] = useState(true)
  useAsyncEffect(async () => {
    if (!skipInitial || retries > 0) {
      setLoading(true)
      const res = await backend.get(endPoint, params)

      if (res.ok) {
        setData(res.data)
      } else {
        setError(true)
      }

      setLoading(false)
    }
  }, [retries, watch])

  return {data, error, loading, retry}
}

export default useGet
