import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useMemo, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'
import {useSavedColumns, getColumnIds} from 'shared/table/index'
import _ from 'lodash'

import {
  Column,
  Table,
  ExpandedState,
  useReactTable,
  getCoreRowModel,
  getPaginationRowModel,
  getFilteredRowModel,
  getExpandedRowModel,
  getGroupedRowModel,
  getSortedRowModel,
  ColumnDef,
  flexRender,
} from '@tanstack/react-table'

const defaultOpts = {
  getExpandedRowModel: getExpandedRowModel(),
  getGroupedRowModel: getGroupedRowModel(),
  getCoreRowModel: getCoreRowModel(),
  getFilteredRowModel: getFilteredRowModel(),
  getSortedRowModel: getSortedRowModel(),
}

const useTable = opts => {
  const {meta, columns, grouping: imperativeGrouping = [], stickyFields = []} = opts
  const columnIds = getColumnIds(columns)

  const {tableName} = meta
  const {savedColumns, activeColumns} = useSavedColumns({tableName, columnIds, stickyFields})

  const [columnVisibility, setColumnVisibility] = React.useState({})
  const [sorting, setSorting] = useState()
  let [grouping, setGrouping] = useState([])
  const [expanded, setExpanded] = useState({})

  // if (imperativeGrouping) grouping = imperativeGrouping

  const _setExpanded = props => {
    if (!_.isFunction(props)) return
    return setExpanded(props)
  }

  const state = useMemo(() => {
    return {sorting, columnVisibility, grouping, expanded}
  }, [expanded, grouping, columnVisibility, sorting])

  const tableData = useReactTable({
    ...opts,
    onColumnVisibilityChange: setColumnVisibility,
    state,
    onSortingChange: setSorting,
    getSubRows: row => row.subRows,
    getRowId,
    onExpandedChange: _setExpanded,
    onGroupingChange: setGrouping,
    ...defaultOpts,
  })

  let columnObj = {}
  activeColumns.forEach(c => (columnObj[c] = true))
  columnIds.filter(c => !activeColumns.includes(c)).forEach(c => (columnObj[c] = false))

  useEffect(() => {
    tableData.setColumnVisibility(old => columnObj)
  }, [savedColumns])

  return tableData
}

const getRowId = (shift, idx, parent) => {
  if (!parent) return `${shift.id}-${shift.user_id}`
  return shift?.id?.toString() || idx
}

export default useTable
