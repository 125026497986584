import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useIndex from 'hooks/use_index'

const Ticket = ({item}) => {
  const envs = useAll("environments")
  const env = envs.find(e => e.id === item.environment_id) || {}
  const data = JSON.stringify(item.data)
  return (
      <div>
        <p> env: {env.naam} </p>
        <p> user: {item.user_id} | {item.email} </p>
        <p> desc: {item.description} </p>
        <p> sentryId: {item.sentry_id} </p>
        <p> date: {moment(item.date || item.created_at).format("DD-MM-YYYY")} </p>
        {item.image.url && <img style={{height: 200, width: 200}} src={item.image.url}/>}
        {item.file.url && <a href={item.file.url}> DL file </a>}
        <Button onClick={() => console.log(data) }> show data </Button>

        <p> ---------------- </p>
      </div>
    )
}

const Page = () => {
  const tickets = useIndex("tickets")

	return (
			<div style={{marginTop: 80}}>
        {tickets.map(t => <Ticket item={t}/> )}
			</div>
	)
}

export default Page;
