import utils from '@eitje/utils'
import {Button, Input} from 'antd'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import {Fragment, useState} from 'react'
import React from 'react'

const filterItems = (items, query, {searchField = 'name', filterFunc, showSearch = true}) => {
  if (!showSearch || !utils.exists(query)) return items
  if (filterFunc) return filterFunc(items, query)
  return utils.search(items, query, {field: searchField})
}

const useSearch = (items, {inputProps, SearchContainer = Fragment, placeholder = t('typeToSearch'), defaultSearch, ...rest} = {}) => {
  const [search, setSearch] = useState(defaultSearch)
  const filteredItems = filterItems(items, search, rest)

  const searchInput = (
    <SearchContainer>
      <Input
        id="search"
        placeholder={placeholder}
        value={search}
        autocomplete="off"
        onChange={e => setSearch(e.target.value)}
        {...inputProps}
      />
    </SearchContainer>
  )

  return {filteredItems, search, setSearch, searchInput}
}

export default useSearch

export const SearchEmpty = ({action = _.noop}) => {
  return (
    <div className="searchEmpty veedree modalEitje">
      <div className="searchEmptyText fCol aCen jCen">
        <p className="bold p24">{t('noResults')}</p>
        <p className="smallP">{t('noResultsSearch')}</p>
        <Button onClick={action} style={{marginTop: 24}} type="primary">
          {t('clearFilter')}
        </Button>
      </div>
    </div>
  )
}
