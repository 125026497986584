import utils from '@eitje/utils'
import {Button, Popover, Affix} from 'antd'
import useSearch, {SearchEmpty} from 'hooks/use_search'
import useSort from 'hooks/use_sort'
import {store} from 'index'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import moment from 'moment'
import React, {Fragment, useRef, useState} from 'react'
import {useSelector} from 'react-redux'
import 'styles/table.css'
import useTable from 'hooks/use_table'
import * as jsUtils from 'utils/jsutils'

const likeDate = val => {
  if (!_.isString(val)) return false
  const mmt = moment(val, ['YYYY-MM-DD', 'YYYY-MM-DDTHH:mm:ss.SSSZ', 'YYYY-MM-DDTHH:mm:ss.SSSSSSZ'], true) // 3rd arg is strict mode, needed cuz some nums are otherwise interpreted as dates
  return mmt.isValid()
}

const renderDefaultCell = (item, field) => {
  const val = _.get(item, field) // we might need nested access

  if (val && likeDate(val)) {
    return moment(val).format('DD-MM-YYYY')
  }
  if (_.isBoolean(val)) {
    return val ? '✓' : '✖'
  }

  if (_.isArray(val)) {
    return val.join(', ')
  }

  if (_.isObject(val)) {
    return JSON.stringify(val)
  }

  return val
}

const disallowedChildClasses = ['ant-btn', 'actionBTN', 'ant-popover'] // bit hacky, idea is to prevent certrain children from triggering tr's click
// without having to explicitly set stopPropagation in the component. For now only use-case is a popover
// that's why it makes sense to use the offsetParent.

const hasClass = e => disallowedChildClasses.some(c => e.classList.contains(c))

const Row = ({
  item,
  content = () => {},
  className,
  columnProps = {},
  onClick = () => {},
  title = () => {},
  fields,
  style,
  placement,
  showActions,
}) => {
  const sharedProps = {}
  const [mouseIn, setMouseIn] = useState(false)
  const handleMouseMove = () => setMouseIn(s => !s)
  if (placement) sharedProps['placement'] = placement
  const popover = useRef(null)
  const {originalItem} = item
  return (
    <tr
      key={originalItem.id}
      onClick={e => !hasClass(e.target.offsetParent) && onClick(originalItem)}
      className={`tableRow ${className}`}
      style={style}
    >
      {fields.map((f, idx) => {
        const cProps = utils.funcOrObj(columnProps, idx)
        return (
          <td className="table-cell" {...cProps}>
            {' '}
            {item[f]?.label}{' '}
          </td>
        )
      })}
      {showActions && (
        <Popover
          destroyTooltipOnHide
          ref={popover}
          id="popover"
          content={() => content(originalItem, {popover: popover.current})}
          title={() => title(originalItem)}
          {...sharedProps}
        >
          <td onMouseOver={() => handleMouseMove()} onMouseOut={() => handleMouseMove()} className="actionBTN">
            <img className="" src={mouseIn ? '/images/web/icons/dots.png' : '/images/web/icons/dotsInactive.png'} />
          </td>
        </Popover>
      )}
    </tr>
  )
}

const renderHeader = (f, up, active) => {
  return (
    <Fragment>
      {t(`table.${f}`, t(`records.${f}`))}{' '}
      {active ? (
        up ? (
          <img src="/images/web/icons/ascending.png" style={{width: 12}} />
        ) : (
          <img src="/images/web/icons/descending.png" style={{width: 12}} />
        )
      ) : (
        <img src="/images/web/icons/noOrder.png" style={{width: 12}} />
      )}
    </Fragment>
  )
}

const Headers = ({fields, hideHeaders, showActions, sortField, onClick, up}) => {
  return (
    <Fragment>
      {fields.map(f => (
        <th className="headers" onClick={() => onClick(f)}>
          {' '}
          {hideHeaders.includes(f) ? '' : renderHeader(f, up, sortField === f)}{' '}
        </th>
      ))}
      {showActions && <th>Actions</th>}
    </Fragment>
  )
}

const Table = props => {
  let {
    items = [],
    fields = [],
    showIndex = items.length > 50,
    actionMenu,
    renderEmpty,
    emptyPlaceholder = null,
    actionTitle,
    rowProps = {},
    showCount,
    hideHeaders = [],
    showEmpty = [],
    showTotals = 'top',
    renderCell = () => null,
  } = props

  const makeCell = (item, field, idx) => {
    const val = item[field]
    if (!val && !showEmpty.includes(field) && item.hasOwnProperty(field)) return {label: emptyPlaceholder, value: val, field}
    const cellData = renderCell(item, field, idx) || renderDefaultCell(item, field, idx)
    if (_.has(cellData, 'value')) return cellData
    return {label: cellData, value: _.get(item, field)}
  }

  if (showIndex) {
    fields = ['idx', ...fields]
  }

  const makeTableItem = (item, idx) => {
    const obj = {}
    fields.forEach(f => (obj[f] = makeCell(item, f)))
    return {...obj, originalItem: item}
  }

  const tableItems = items.map((i, idx) => makeTableItem(i, idx))

  let {changeSort, asc, sortedItems, sortField} = useSort({...props, items: tableItems, nested: true})

  sortedItems = sortedItems.map((i, idx) => ({...i, idx: {label: idx + 1, value: idx + 1}}))

  const {showActions} = rowProps

  return (
    <div className="veedreeTable veedree">
      <table>
        {showCount && `(${items.length})`}
        <tr>
          <Headers
            showActions={showActions}
            up={asc}
            sortField={sortField}
            onClick={changeSort}
            fields={fields}
            hideHeaders={hideHeaders}
          />
        </tr>

        {showTotals == 'top' && <Totals makeTableItem={makeTableItem} items={items} fields={fields} />}

        {sortedItems.map(i => {
          const rProps = utils.funcOrObj(rowProps, i)
          return <Row title={actionTitle} content={actionMenu} item={i} fields={fields} {...rProps} />
        })}

        {items.length === 0 && renderEmpty}

        {showTotals == 'bottom' && <Totals makeTableItem={makeTableItem} items={items} fields={fields} />}
      </table>
    </div>
  )
}

const Totals = ({items, makeTableItem, fields}) => {
  const summed = jsUtils.sumObject(items, ['tier', 'cost_ids', 'id', 'idx'])
  const item = makeTableItem({...summed, name: 'Total'})
  const _item = {name: 'Totaal', ...item}
  return <Row className="table-row-totals" item={_item} fields={fields} />
}

export default Table
export {Table, useTable}
