import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, DatePicker, Select} from 'components/form/types'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import utils from '@eitje/web_utils'
import Modal from 'components/general/modal'
import Form from './form'

const Page = () => {
  return (
      <Modal>
        <Form/>

      </Modal>
  )
}

export default Page;
