import {usePicker} from '@eitje/form'
import utils from '@eitje/web_utils'
import useSearch from 'hooks/use_search'
import useToggleState from 'hooks/use_toggle_state'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment, useState} from 'react'
import {crazyFilter} from 'selectors/records'

export const getNewVal = (val, newVal) => {
  if (_.isArray(newVal)) {
    const hasAll = newVal.every(v => val.includes(v))
    return hasAll ? val.filter(v => !newVal.includes(v)) : _.uniq([...val, ...newVal])
  }
  return utils.toggle(val, newVal)
}

export const isSelected = (item, value) => {
  if (_.isArray(item.value)) {
    return item.value.every(v => value.includes(v))
  }
  if (value.length === 0) return item.value === null // empty array is null
  return value.includes(item.value)
}
