import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal, Select, DatePicker} from 'antd'
import api, {fileDomain} from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Form} from 'react-advanced-form'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useToggleState from 'hooks/use_toggle_state'
import 'antd/dist/antd.min.css'

const Option = Select.Option

const roles = ['admin', 'manager', 'uren_accorderen', 'uren_schrijven', 'contracten', 'financieel', 'verlof']
const statusses = ['actief', 'demo', 'eitje', 'inactief']
const defStatusses = statusses.slice(0, 3)

const makePath = path => path.replace('app//', 'app/')

const DownloadButton = ({url, params = {}, name = 'Env excel'}) => {
  const [exc, setExcel] = useState()
  const [loading, setLoading] = useState(false)

  const getExcel = async () => {
    setLoading(true)
    const res = await api.get(url, params)
    setLoading(false)
    if (res.ok) {
      setExcel(res.data.url)
    }
  }

  return (
    <div style={{margin: 10}}>
      {exc ? (
        <a target="_blank" href={makePath(`${fileDomain}${exc}`)}>
          {' '}
          <Button> Download {name} </Button>{' '}
        </a>
      ) : (
        <Button onClick={!loading ? getExcel : () => {}}> {!loading ? `Get ${name}` : `${name} Loading... `} </Button>
      )}
    </div>
  )
}

const Financial = () => {
  const [selMonth, setSelMonth] = useState(false)
  return (
    <div>
      <DatePicker picker="month" onChange={date => setSelMonth(date)} />
      {selMonth && <DownloadButton url={`environments/financial?date=${selMonth}`} name="Financieel" />}
    </div>
  )
}

const Page = () => {
  const [exc, setExcel] = useState()
  const [userExcel, setUsers] = useState()
  const [loading, setLoading] = useState(false)

  const [selectedRoles, setRoles] = useState(roles)
  const [status, setStatus] = useState(defStatusses)
  const [userVis, setUserVis] = useState(false)
  const [financialVis, setFinancialVis] = useState(false)

  const req = async ({url, callback}) => {
    setLoading(true)
    const res = await api.get(url)
    setLoading(false)
    if (res.ok) {
      callback(res.data.url)
    }
  }

  return (
    <div className="topDiv" style={{}}>
      <div className="tableAround">
        <DownloadButton name="Env" url="environments/all" />
        <Button onClick={() => setFinancialVis(!financialVis)}>{financialVis ? 'reset' : `Export financieel`}</Button>

        {financialVis && <Financial />}

        {!userVis && <Button onClick={() => setUserVis(true)}> Export users </Button>}
        {userVis && (
          <Fragment>
            <p> Status </p>
            <Select mode="multiple" value={status} onChange={val => setStatus(val)}>
              {statusses.map(s => (
                <Option value={s}> {s} </Option>
              ))}
            </Select>

            <p> Rol </p>
            <Select mode="multiple" value={selectedRoles} onChange={val => setRoles(val)}>
              {roles.map(s => (
                <Option value={s}> {s} </Option>
              ))}
            </Select>

            <DownloadButton params={{roles: selectedRoles, status}} name="users" url="environments/users" />
            <Button
              onClick={() => {
                setUserVis(false)
              }}
            >
              {' '}
              reset{' '}
            </Button>
          </Fragment>
        )}
      </div>
    </div>
  )
}

export default Page
