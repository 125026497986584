import React, {Fragment, useState} from 'react'
import {Button, Input, Menu, Dropdown} from 'antd'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import _ from 'lodash'
import {NavLink, Link, useLocation} from 'react-router-dom'
import {history} from 'index'
import {logout as _logout} from 'actions/auth'
import {useSelector} from 'react-redux'
import {getMemory} from 'actions/general'
import SideBarIcon from 'components/SideBarIcon'
import {store} from 'index'
import {AuditOutlined, FileTextOutlined, LeftCircleTwoTone, RightCircleTwoTone} from '@ant-design/icons'
import useIsAdmin from 'hooks/use_is_admin'
import useIsModerator from 'hooks/use_is_moderator'

const logout = () => {
  store.dispatch({type: 'RESET_DATA'})
  _logout()
}

const navLinks = [
  {url: '/home', moderator: true, text: 'Home'},
  {url: '/environments', text: 'Envs', nestedUrls: ['/orgs', '/billing_agents', '/inactive_envs']},
  {url: '/customer_map', moderator: true, text: 'Map'},
  {url: '/downloads', moderator: true, text: 'Export users'},
  {url: '/billing/orgs', moderator: true, text: 'Billing'},
  {url: '/admins', moderator: true, text: 'Admins'},
  {url: '/tickets', text: 'Tickets'},
  {url: '/trial_conversion', text: 'Trial'},

  {url: '/records', text: 'Deleted', component: <FileTextOutlined style={{color: '#fff'}} />},
]

const SideMenu = ({clickActive, handleClick}) => {
  const isAdmin = useIsAdmin()
  const isModerator = useIsModerator()
  const {pathname} = useLocation()
  let viewableLinks = navLinks
  if (!isAdmin) {
    viewableLinks = viewableLinks.filter(l => !l.admin)
  }

  if (!isModerator) viewableLinks = viewableLinks.filter(l => !l.moderator)

  return (
    <div className={`sidebar fCol jBetween aCen ${clickActive ? 'navFull' : 'navSmall'}`}>
      <div>
        <Link to="/home">
          <img className="navLogo" src="/images/cirleLogo.png" />
        </Link>
      </div>

      <div style={{}} className="fCol aCen jCen">
        <div className={`nav aCen jCen fCol ${clickActive ? 'jSta' : ''}`}>
          {viewableLinks.map(({url, img, nestedUrls = [], text}) => (
            <NavLink
              activeClassName="active"
              to={url}
              className="linkImg fRow jCen aCen"
              isActive={() => url === pathname || nestedUrls.includes(pathname)}
            >
              <p> {text} </p>
            </NavLink>
          ))}
        </div>
      </div>

      <div onClick={logout} className="hover fCol aCen jCen">
        <p>Log uit</p>
      </div>
    </div>
  )
}

export default SideMenu
