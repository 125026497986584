import React from 'react'
import {PrivateRoute, PublicRoute, AdminRoute, ModeratorRoute} from 'components/PrivateRoute'
import TrialGraph from './pages/graph/index'
import TrialTable from './pages/table/index'
const foregroundRoutes = [
  <PrivateRoute path="/trial_conversion/graph" exact component={TrialGraph} />,
  <PrivateRoute path="/trial_conversion/table" exact component={TrialTable} />,
]

export {foregroundRoutes}
