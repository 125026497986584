import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'


const Page = (props) => {
	const organisations = useAll("organisations")
	const modal = useContext(ModalContext)
	return (
			<div>
				<Form {...props} onSubmit={data => API.create("billing_agents", data)} afterSubmit={modal.goBack}> 
					<Input field="name"/>
					<DropdownPicker multiple showSearch field="organisation_ids" items={organisations}/>
					<Button submitButton> submit </Button>
				</Form>
			</div>
	)
}

export default Page;
