import React, {useState, useEffect, Fragment} from 'react'
import {Button} from 'antd'
import {PlusOutlined} from '@ant-design/icons'
import {API, useAll} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {Input, DropdownPicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink} from 'components/routing'
import useEnvTable from './use_env_table'
import {useToggleState} from '@eitje/react-hooks'

const enrichEnv = e => {
  let orgName
  let orgCount
  let org

  if (e.organisation) {
    orgName = e.organisation.name
    orgCount = e.organisation.environment_ids.length
    org = orgCount > 1 ? `${orgName} (${orgCount})` : orgName
  }

  return {
    ...e,
    org,
    location: !!e.place,
    days_remaining: e.days_remaining < 0 ? null : e.days_remaining,
  }
}

const Index = () => {
  let envs = useAll('environments', {joins: 'organisations'})
  const acts = useAll('billingActivities')
  envs = envs.map(e => ({...acts.find(a => a.environment_id == e.id), ...e}))
  envs = envs.map(enrichEnv)
  const envDate = envs[0]?.date
  const orgs = useAll('organisations').filter(o => o.environment_ids.length > 1)

  const [signingFilter, setSigningFilter] = useState([])
  const [orgFilter, setOrgFilter] = useState(null)

  let items = envs

  const signingStatusses = _.uniq(items.map(i => i.signing_status))

  if (utils.exists(signingFilter)) {
    debugger
    items = envs.filter(e => signingFilter.includes(e.signing_status))
  }

  if (orgFilter) {
    items = items.filter(e => e.organisation_id == orgFilter)
  }

  const {table, searchInput, columnPicker} = useEnvTable({items})

  const statusItems = signingStatusses.filter(Boolean)

  return (
    <div className="topDiv" style={{}}>
      <div className="hTable fRow jBetween aCen hideMob">
        <div className="select fRow">
          <DropdownPicker
            showSearch
            onChange={val => setOrgFilter(val)}
            items={[...orgs, {id: null, name: 'geen'}]}
            placeholder="Filter op org"
            style={{height: 34, width: 135, marginRight: -1}}
          />
          <DropdownPicker
            multiple
            items={statusItems}
            onChange={val => setSigningFilter(val)}
            placeholder="Filter op signing"
            style={{height: 34, width: 135, marginRight: -1}}
          />

          <Button> Laatst berekend op: {utils.formatDate(envDate)} </Button>

          <ModalLink to="/environments/new">
            <Button className="marginRight blue">
              <PlusOutlined />
              Nieuwe regular
            </Button>
          </ModalLink>
        </div>
      </div>

      {searchInput}
      {columnPicker}
      {table}
    </div>
  )
}

export default Index
