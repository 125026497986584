import React, {Component, Fragment} from 'react'
import { createField, fieldPresets } from 'react-advanced-form'
import {Select as AntSelect, Input as AntInput, DatePicker as AntDatePicker} from 'antd'
import Moment from 'moment'

const t = text => text
const Option = AntSelect.Option;

class Inp extends Component {

handleChange = (nextValue) => {
    /* Dispatch "react-advanced-form" method to update the field state */
    this.props.handleFieldChange({ nextValue })
  }

render() {
  const {fieldProps, secure, fieldState} = this.props
  const {errors, valid, invalid } = fieldState
  const classNames = [valid && 'has-success', invalid && 'has-error'].filter(
    Boolean,
  )
  return (
    <div>
      {secure ? <AntInput.Password  {...fieldProps} name={fieldProps.name || fieldProps.type} className={classNames.join(' ')}
                onChange={e => this.handleChange(e.target.value)}/> : 

                <AntInput {...fieldProps} placeholder={fieldProps.placeholder || fieldProps.name} name={fieldProps.name || fieldProps.type} className={classNames.join(' ')}
                onChange={e => this.handleChange(e.target.value)}  />
              }
       {errors && errors.map((error) => (
        <div className="text--red">{error}</div>
      ))}

    </div>
    )
}

}

export const Input = createField(fieldPresets.input)(Inp)

class _TextArea extends Component {

handleChange = (nextValue) => {
    /* Dispatch "react-advanced-form" method to update the field state */
    this.props.handleFieldChange({ nextValue })
  }

render() {
  const {fieldProps, fieldState} = this.props
  const {errors, valid, invalid } = fieldState
  const classNames = [valid && 'has-success', invalid && 'has-error'].filter(
    Boolean,
  )
  return (
    <div>
       <AntInput.TextArea  {...fieldProps} name={fieldProps.name || fieldProps.type} className={classNames.join(' ')}
                onChange={e => this.handleChange(e.target.value)}/>

       {errors && errors.map((error) => (
        <div className="text--red">{error}</div>
      ))}

    </div>
    )
}

}

export const TextArea = createField(fieldPresets.input)(_TextArea)


class _DatePicker extends Component {
  handleChange = (nextValue) => {
    /* Dispatch "react-advanced-form" method to update the field state */
    this.props.handleFieldChange({ nextValue: nextValue === "" ? undefined : Moment(nextValue, 'DD-MM-YYYY').format("YYYY-MM-DD") })
  }

  render() {
      const {fieldProps, fieldState} = this.props
     const {errors, valid, invalid } = fieldState
     const classNames = [valid && 'has-success', invalid && 'has-error'].filter(
      Boolean,
    )
     const val = fieldState.value ? Moment(fieldState.value) : fieldState.value
     return (
        <Fragment>
          <AntDatePicker format="DD-MM-YYYY" {...fieldProps} placeholder={t("selectDate")} 
          value={val} onChange={(date, dateString) => this.handleChange(dateString)} 
                   className={classNames.join(' ')} />
          {errors && errors.map((error) => (
            <div className="text--red">{error}</div>
          ))}
        </Fragment>
       
      )
  }
}

export const DatePicker = createField(fieldPresets.input)(_DatePicker)


class Sel extends Component {

   handleChange = (nextValue) => {
    /* Dispatch "react-advanced-form" method to update the field state */
    this.props.handleFieldChange({ nextValue })
  }

  render() {
     const {idField = "id", displayField="naam", options, fieldProps, fieldState,
           multiple, showSearch = true, placeholder} = this.props
     const {errors, valid, invalid } = fieldState
     const classNames = [valid && 'has-success', invalid && 'has-error'].filter(
      Boolean,
    )
    let val = fieldState.value;
    if(multiple && fieldState.value === "") val = [];

    let condOpts = {}
    if(showSearch) {
      condOpts['filterOption'] = (input, option) => option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
      condOpts['optionFilterProp'] = 'children'
      condOpts['showSearch'] = true
    }

     return (
          <div>
      <AntSelect {...fieldProps} {...condOpts} placeholder={placeholder} value={val} onChange={(val) => this.handleChange(val)} 
                 mode={multiple ? 'multiple' : 'default'} className={classNames.join(' ')}>
        {options.map(opt => 
          <Option key={opt[idField] || opt} value={opt[idField] || opt}>
            {opt[displayField] || opt}
          </Option> 
          )}

      </AntSelect>
       {errors && errors.map((error) => (
        <div className="text--red">{error}</div>
      ))}

    </div>

      )
  }

}
  
 


export const Select = createField(fieldPresets.select)(Sel)

