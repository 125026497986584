import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'

const SelectAllButton = ({count, ...props}) => {
  const text = count ? 'common.deselect_all' : 'common.select_all'
  return (
    <EitjeButton {...props} className="select-all-button" icon={'checkbox'}>
      {t(text)}
    </EitjeButton>
  )
}

const SettingsButton = ({to = 'settings', ...rest}) => {
  return <ModalLinkBtn {...rest} to={to} icon="adjust" text={t('common.settings')} />
}

const MailToButton = ({email, text = t('common.contact_via_mail'), ...rest}) => {
  return (
    <a href={`mailto:${email}`}>
      <EitjeButton icon="edit" text={text} {...rest} />
    </a>
  )
}

const ExternalLinkButton = ({to, ...rest}) => {
  return (
    <a href={to} target="_blank">
      <EitjeButton className="external-link-button" icon="link" {...rest} />
    </a>
  )
}

export const ArrowButton = rest => {
  return <EitjeButton className="arrow-button" icon="arrow" {...rest} />
}

const docsBaseUrl = 'https://intercom.help/eitje/nl'
const articleUrl = `${docsBaseUrl}/articles`
const collectionUrl = `${docsBaseUrl}/collections`

const DocsLink = ({to, collection, ...rest}) => {
  const baseUrl = collection ? collectionUrl : articleUrl
  const href = `${baseUrl}/${to}`
  return <a target="_blank" href={href} {...rest} />
}

const DocsButton = ({noText, text = noText ? null : t('common.read_more_about_feature'), ...props}) => {
  return (
    <DocsLink {...props}>
      <EitjeButton height="small" icon="link" children={text} className="docs-button" {...props} />
    </DocsLink>
  )
}

export const ClearButton = props => {
  return <EitjeButton className="clear-button" {...props} />
}

export {SelectAllButton, MailToButton, ExternalLinkButton, DocsLink, DocsButton, SettingsButton}
