import {Button, PageHeader} from 'antd'
import {backend} from '@eitje/easy_api'
import {RecordForm, RecordsTable, SearchQuery} from 'components/deletedRecords/deletedRecords'
import api from 'initializers/api'
import React from 'react'
import * as jsUtils from 'utils/jsutils'
import {store} from 'index'
import {useAsyncEffect} from '@eitje/react-hooks'
// {db_table, start_date, end_date, env_id, env_name}

const sync = async () => {
  const res = await api.get(`deleted_records/sync_now`)
  if (res.ok) {
    jsUtils.openNotif('sync gestart', 'info', 'duurt ongeveer een minuutje')
  }
}

const getDeletedTax = async () => {
  const res = await backend.get(`deleted_records/taxonomy`)
  if (res.ok) {
    store.dispatch({type: 'SET_DELETED_TAX', payload: res.data.models})
  }
}

const Records = () => {
  useAsyncEffect(getDeletedTax)
  return (
    <div className="topDiv">
      <PageHeader className="deletedRecordsHeader" extra={[<Button onClick={sync}> Sync now </Button>]} title="Deleted Records" />

      <div style={{marginBottom: '24px'}}>
        <RecordForm />
      </div>

      <SearchQuery />

      <RecordsTable />
    </div>
  )
}

export default Records
