import React, {useState, useEffect, Fragment} from 'react'
import {Button, Modal} from 'antd'
import api from 'initializers/api'
import _ from 'lodash'
import {useSelector} from 'react-redux'
import {extendSelector} from 'selectors/records'

const enrichOrg = org => ({
	...org,
	amtEnvs: org?.environment_ids?.length,
	has_custom: org.billingInfos.length > 1 || org.billingAgreements.length > 1 || org.billingDistributions.length > 1,
})

const useExtendOrg = orgs => {
	orgs = useSelector(state => extendSelector(state, 'billingAgreements', orgs))
	orgs = useSelector(state => extendSelector(state, 'billingInfos', orgs))
	orgs = useSelector(state => extendSelector(state, 'billingDistributions', orgs))
	orgs = orgs.filter(Boolean).map(o => enrichOrg(o))
	return orgs
}

export default useExtendOrg
