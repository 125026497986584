import _ from 'lodash'

if (!Array.prototype.flat) {
  Object.defineProperty(Array.prototype, 'flat', {
    value: function(depth = 1) {
      return this.reduce(function(flat, toFlatten) {
        return flat.concat(Array.isArray(toFlatten) && depth > 1 ? toFlatten.flat(depth - 1) : toFlatten)
      }, [])
    },
  })
}

Array.prototype.sum = function(mapper) {
  const baseArray = mapper ? this.map(mapper) : this
  return baseArray.reduce((tally, val) => tally + val, 0)
}

Array.prototype.avg = function(mapper) {
  const total = this.sum(mapper)
  return total / this.length
}

Array.prototype.uniq = function() {
  var a = []
  for (var i = 0, l = this.length; i < l; i++) if (a.indexOf(this[i]) === -1) a.push(this[i])
  return a
}

Array.prototype.sortBy = function(fn, sort = null) {
  return _.orderBy(this, fn, sort)
}
