import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import useIndex from 'hooks/use_index'

const fields = ['email', 'name', 'role']

const rowProps = {onClick: i => navigateModal(`/admins/${i.id}`)}

const Page = () => {
	const admins = useIndex("admins")
	const {table, searchInput} = useTable({items: admins, showTotals: false, rowProps, fields})
	return (
			<div className="topDiv">
				<ModalLinkBtn to="/admins/new" text="New"/>
				{searchInput}
				{table}
			</div>
	)
}

export default Page;
