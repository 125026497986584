import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Popconfirm} from 'antd'
import {Horizontal, Button, Tooltip, UserImage, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'
import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {useForm, ContextForm} from '@eitje/form'

export const EitjeButton = ({
  height = 'large',
  width = 'regular',
  type = 'regular',
  disabled,
  icon,
  hoverIcon,
  dropdown,
  iconSize = 'default',
  textTransform = 'capitalize-first-letter',
  text,
  noText,
  noHover,
  children,
  className,
  ...rest
}) => {
  if (!children) children = text
  const classNames = utils.makeCns(
    'eitje-button',
    `eitje-button-${height}-height`,
    `eitje-button-${width}-width`,
    `eitje-button-type-${type}`,
    `eitje-button-icon-size-${iconSize}`,
    `eitje-button-text-transform-${textTransform}`,
    icon && `eitje-button-has-icon`,
    icon && `eitje-button-has-${icon}-icon`,
    hoverIcon && `eitje-button-hover-icon`,
    dropdown && `eitje-button-has-dropdown-icon`,
    disabled && 'eitje-button-disabled',
    noText && 'eitje-button-no-text',
    noHover && 'eitje-button-no-hover',
    className,
  )
  const iconElement = icon ? <img className="eitje-button-icon" src={`/button_icons/${icon}.png`} /> : null
  const dropdownSrc = type === 'blue' ? `/button_icons/down_arrow_white.png` : `/button_icons/down_arrow.png`
  const dropdownIconElement = dropdown ? <img className="eitje-button-dropdown-icon" src={dropdownSrc} /> : null

  return (
    <button className={classNames} {...rest} type="button">
      {iconElement}
      <span className="eitje-button-text">{children}</span>
      {dropdownIconElement}
    </button>
  )
}

export default EitjeButton
