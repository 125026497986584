import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {persist, store, history} from 'index'
import _ from 'lodash'
// import {entitySelector, entitiesSelector} from 'selectors/entities'
import pluralize from 'pluralize'
import {t} from 'initializers/i18n'
import Moment from 'moment'
import * as DB from 'actions/entities'
import {backend, API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'

export async function getRich({allIds} = {}) {
  const send_invoice_ids = allIds
  if (send_invoice_ids.length == 0) return utils.errNotif('gap', 'wil je geld verdienen of wa?')
  const params = {send_invoice_ids}
  const send = () => sendInvoices(params)
  jsUtils.showConfirm('Are you ready..', 'To get rich?', send)
}

export const generateInvoices = async params => {
  const res = await backend.post(`billing_invoices/generate`, params)
  if (res.ok) {
    utils.openNotif('Lekker gap', 'success', 'Facturen worden nu gemaakt')
  }
  return res
}

export const sendInvoices = async params => {
  const res = await backend.post('billing_invoices/send_invoices', params)
  if (res.ok) {
    utils.openNotif('Lekker gap', 'success', 'Geld rolt binnen')
    history.replace('/billing/orgs')
  } else {
    utils.openNotif('Taaie dobber', 'error', 'Er is iets misgegaan en er zijn geen facturen verstuurd')
  }
}

export function getBilling() {
  API.index('billing_agreements')
  API.index('billing_infos')
  API.index('billing_distributions')
}
