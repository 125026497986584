import {BorderOuterOutlined, PropertySafetyOutlined} from '@ant-design/icons'
import {find} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {Button, Input, Popconfirm, Popover as AntPopover, Steps, Progress, Tooltip as AntTooltip, Select as AntSelect, Checkbox} from 'antd'
import {history} from 'index'
import _ from 'lodash'
import React, {forwardRef, Fragment, useRef, useState} from 'react'
import {useSelector} from 'react-redux'

export const Tooltip = ({title, innerRef, hidden = !utils.exists(title), children, ...rest}) => {
  const ref = useRef(null)

  // prop is called title
  if (hidden) return children
  return <AntTooltip {...rest} ref={innerRef} title={title} children={utils.alwaysArray(children)} />
} // we have to convert children to array here because Tooltip doesn't show if it has only one child & that child is a custom component. Arrays do always work..
// It looks like the positioning of the tooltip is slightly off after the array-trick, but that's something we'll have to accept for now

export const InfoTooltip = ({title}) => {
  return (
    <Tooltip className={'tooltip-icon-wrapper'} title={title}>
      <img src={'/images/web/icons/infoIcon.png'} className="tooltip-icon" />
    </Tooltip>
  )
}
