import 'antd/dist/antd.min.css'
import 'initializers/string'
import 'styles/app.css'
import React, {Fragment, useState} from 'react'
import {Link} from 'react-router-dom'
import {useEffect} from 'react'
import {useSelector} from 'react-redux'
import {Button, Input, Affix, Select, Menu, Dropdown} from 'antd'
import {Route, Switch, Redirect, useLocation} from 'react-router-dom'
import './App.css'
import AuthProvider from 'components/providers/auth_provider'
import {PrivateRoute, PublicRoute, AdminRoute, ModeratorRoute} from 'components/PrivateRoute'
import Login from 'pages/login'
import Home from 'pages/home'
import SideMenu from 'pages/side_menu'
import SmallNav from 'pages/smallMenu'
import Envs from 'pages/environments/index'
import Records from 'pages/records'
import InactiveEnvs from 'pages/environments/inactive'
import Orgs from 'pages/organisations/index'
import NewOrg from 'pages/organisations/new'
import EditOrg from 'pages/organisations/edit'

import NewEnvironment from 'pages/environments/new'
import ShowEnv from 'pages/environments/show'

import BillingAgents from 'pages/billing/agents/index'
import NewBillingAgent from 'pages/billing/agents/new'
import EditBillingAgent from 'pages/billing/agents/edit'
import Billing from 'pages/billing/billing'

import EditBillingInfo from 'pages/billing/infos/edit'
import NewBillingInfo from 'pages/billing/infos/new'
import EditBillingDistribution from 'pages/billing/distributions/edit'
import NewBillingDistribution from 'pages/billing/distributions/new'
import EditBillingAgreement from 'pages/billing/agreements/edit'
import NewBillingAgreement from 'pages/billing/agreements/new'
import InvoiceRun from 'pages/billing/invoices/run'
import InvoiceSituationRoom from 'pages/billing/invoices/situation_room'

import Admins from 'pages/admins/index'
import NewAdmin from 'pages/admins/new'
import EditAdmin from 'pages/admins/edit'
import TrialConversion from 'cores/trial_conversion/pages/index'
import CustomerMap from 'pages/customer_map'
import Search from 'pages/search'
import User from 'pages/user'
import Downloads from 'pages/downloads'
import {getMemory} from 'actions/general'
import {logout} from 'actions/auth'
import Tickets from 'pages/tickets'
import SideBarIcon from 'components/SideBarIcon'
import {CaretDownOutlined} from '@ant-design/icons'
import {history, store} from 'index'

const branchMenu = (
  <Menu>
    <Menu.Item>
      <Link to="/environments">Vestigingen</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/organisations">Organisaties</Link>
    </Menu.Item>
    <Menu.Item>
      <Link to="/billing_agents">Billing</Link>
    </Menu.Item>
    <Menu.Item danger>
      <Link to="/environments/inactive">Inactief</Link>
    </Menu.Item>
  </Menu>
)

const App = () => {
  const user = useSelector(state => state.auth.user) // listen so we know when to log out
  const location = useLocation()
  const [menuVisible, setMenuVisible] = useState(false)
  const isKorvet = location.pathname === '/korvet'
  const mem = useSelector(state => state.app.mem)
  const storage = useSelector(state => state.app.storage) || 0
  const isMap = location.pathname === '/customer_map'
  // const [clickActive, setClickActive] = useState(false)
  // const handleClick = click => {setClickActive(click)}
  const envName = location.pathname === '/environments' && 'Vestigingen'
  const orgName = location.pathname === '/organisations' && 'Organisaties'
  const bilName = location.pathname === '/billing_agents' && 'Billing'
  const inaName = location.pathname === '/inactive_envs' && 'Inactief'

  const name = envName || orgName || bilName || inaName || 'Menu'

  useEffect(() => {
    store.dispatch({type: 'STOP_LOADING'})
  }, [])

  let background = location.state && location.state.background

  return (
    <Fragment>
      <PublicRoute path="/login" component={Login} exact />
      <PublicRoute path="/" component={Login} exact />

      <AuthProvider>
        <div className="hideMob">
          <div id="header" className="fRow aCen jBetween">
            <div>
              <Dropdown overlay={branchMenu}>
                <div className="headerText fRow aCen">
                  <p>{name}</p>

                  <CaretDownOutlined style={{paddingLeft: 4, fontSize: 12}} />
                </div>
              </Dropdown>
            </div>

            <div className="fRow">
              <div onClick={getMemory} style={{}} className="ant-btn fCol jCen aCen">
                <p style={{}}> RAM: {mem.toFixed(2)}% </p>
              </div>
              <div onClick={getMemory} style={{}} className="ant-btn fCol jCen aCen">
                <p style={{}}> storage: {storage.toFixed(2)}% </p>
              </div>

              <Input.Search
                placeholder="Zoek een teamlid"
                onSearch={value => history.push(`/search?term=${value}`)}
                style={{marginLeft: -1, width: 240}}
              />
            </div>
          </div>
        </div>

        <SmallNav />

        <div className="hideMob">
          <SideMenu />
        </div>

        <div className="fRow">
          <div className={isKorvet || isMap ? 'full' : 'containerContent'}>
            <Switch location={background || location}>
              <PrivateRoute path="/" exact component={Home} />
              <ModeratorRoute path="/home" component={Home} />
              <PrivateRoute path="/environments" component={Envs} exact />
              <PrivateRoute path="/tickets" component={Tickets} />
              <PrivateRoute path="/environments/inactive" component={InactiveEnvs} />
              <PrivateRoute path="/organisations" component={Orgs} />
              <PrivateRoute path="/billing_agents" component={BillingAgents} />
              <ModeratorRoute path="/customer_map" component={CustomerMap} />
              <PrivateRoute path="/search" component={Search} />
              <ModeratorRoute path="/downloads" component={Downloads} />
              <ModeratorRoute path="/billing" component={Billing} />
              <AdminRoute path="/get_rich" component={InvoiceRun} />
              <PrivateRoute path="/records" component={Records} />
              <PrivateRoute path="/users/:id" exact component={User} />
              <AdminRoute path="/admins" exact component={Admins} />
              <PrivateRoute path="/trial_conversion" component={TrialConversion} />
            </Switch>
          </div>

          {background && (
            <Switch>
              <AdminRoute path="/admins/new" component={NewAdmin} />
              <AdminRoute path="/admins/:id" component={EditAdmin} />
              <ModeratorRoute path="/environments/new" component={NewEnvironment} />
              <PrivateRoute path="/environments/:id" component={ShowEnv} />
              <ModeratorRoute path="/organisations/new" component={NewOrg} />
              <PrivateRoute path="/organisations/:id" component={EditOrg} />
              <PrivateRoute path="/billing/infos/new" component={NewBillingInfo} />
              <ModeratorRoute path="/billing_agents/new" component={NewBillingAgent} />
              <PrivateRoute path="/billing_agents/:id" component={EditBillingAgent} />
              <ModeratorRoute path="/billing/infos/:id" component={EditBillingInfo} exact />
              <ModeratorRoute path="/billing/agreements/new" component={NewBillingAgreement} />
              <ModeratorRoute path="/billing/agreements/:id" component={EditBillingAgreement} />
              <ModeratorRoute path="/billing/distributions/new" component={NewBillingDistribution} />
              <ModeratorRoute path="/billing/distributions/:id" component={EditBillingDistribution} />
              <ModeratorRoute path="/billing/invoices/situation_room" component={InvoiceSituationRoom} />
            </Switch>
          )}
        </div>
      </AuthProvider>
    </Fragment>
  )
}

export default App
