import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import {persist, store, history} from 'index'
import _ from 'lodash'
// import {entitySelector, entitiesSelector} from 'selectors/entities'
import pluralize from 'pluralize'
import {t} from 'initializers/i18n'
import Moment from 'moment'
import * as DB from 'actions/entities'


export const getMemory = async () => {
  const res = await api.get(`memory`)
  if(res.ok && res.data) {
    store.dispatch({type: 'GET_MEMORY', payload: res.data.mem, storage: res.data.storage})
  }
}