import {flexRender} from '@tanstack/react-table'
import {t} from 'initializers/i18n'
import _ from 'lodash'

export const renderCell = cell => flexRender(cell.column.columnDef.cell, cell.getContext())
export const defaultCellRender = ({getValue}) => {
  const val = getValue()
  if (_.isBoolean(val)) return val ? 'yes' : 'no'
  return getValue() || null
}

export const buildColumn = c => {
  if (_.isString(c)) c = {accessorKey: c}
  const id = getColumnId(c)
  let obj = {cell: defaultCellRender, ...c, hasAggCell: c.aggregatedCell}
  if (!obj.accessorKey) obj['id'] = id
  return obj
}

const getColumnValue = (column, item) => column.accessorFn?.(item) || item[column.id]

export const getColumnIds = columns => {
  return columns.map(c => c.id || c.accessorKey)
}

const getColumnId = column => column.id || column.accessorKey
