import {useBackground} from 'hooks/use_background'
import {useSelector} from 'react-redux'
import {where, useFind} from '@eitje/easy_api'

export const useBackgroundOrg = () => {
  let orgId
  let envId
  const {pathname, ...rest} = useBackground()
  if (!pathname) return null
  const pathParts = pathname.split('/')
  if (pathname.includes('/billing/orgs')) return {orgId: pathParts.filter(f => Number(f))[0]}
  if (pathname.includes('/environments/')) return {envId: pathParts.filter(f => Number(f))[0]}
  return {}
}

export const useBackgroundEnvs = () => {
  const {orgId, envId} = useBackgroundOrg()
  let query
  if (orgId) query = {organisation_id: orgId}
  const env = useFind('environments', envId)
  if (env.id) query = {organisation_id: env.organisation_id}

  const envs = useSelector(state => where(state, 'environments', query))
  return envs
}

export default useBackgroundEnvs
