import React, {useState, useEffect, useRef, useContext, Fragment} from 'react'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link} from 'react-router-dom'
import {Input, DatePicker, DropdownPicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {Button} from 'antd'
import {useSelector} from 'react-redux'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import useParams from 'hooks/use_params'
import {Spin} from 'antd'
import Form from './form'
import {all, API} from '@eitje/easy_api'
import utils from '@eitje/web_utils'
import {DeleteButton} from 'components/ui'

const Page = () => {
	const {id} = useParams()

	return (
		<Modal title={`Billing info #${id}`}>
			<Content id={id} />
		</Modal>
	)
}

const Content = ({id}) => {
	const envs = useSelector(state => all(state, 'environments'))
	const {data} = useGet(`billing_infos/${id}`)
	if (!utils.exists(data)) return <Spin loading />
	const {item} = data
	const {mollie_dashboard_url} = item

	return (
		<div>
			<a target="_blank" href={mollie_dashboard_url}>
				<Button> Mollie dashboard </Button>
			</a>
			<p> envs die hier bij horen: {item.env_names} </p>
			<Form initialValues={item} />
			{item.mollie_mandate && (
				<DeleteButton onDel={() => API.resourceReq(`billing_infos`, 'revoke_mollie', {id})}> revoke mollie </DeleteButton>
			)}
		</div>
	)
}

export default Page
