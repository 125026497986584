import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'
import {NavLink} from 'react-router-dom'
import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'

const Header = () => {
  return (
    <div>
      <NavLink to="/trial_conversion/graph"> graph </NavLink>
      <NavLink to="/trial_conversion/table"> table </NavLink>
    </div>
  )
}

export default Header
