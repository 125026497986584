import utils from '@eitje/utils'
import {activeFields} from 'pages/environments/use_env_table'

const initialState = {
  envTable: activeFields,
}

export default function reduce(state = initialState, action) {
  switch (action.type) {
    case 'TOGGLE_FILTER':
      const currentVal = state[action.name]
      return {
        ...state,
        [action.name]: utils.toggle(currentVal, action.value),
      }

    case 'SET_FILTER':
      const val = action.value || []
      return {
        ...state,
        [action.name]: val,
      }

    case 'RESET_FILTER':
      return {
        ...state,
        [action.name]: initialState[action.name],
      }

    default:
      return state
  }
}
