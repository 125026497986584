import React, {useState, useEffect, useContext, Fragment} from 'react'
import {Button, Tabs, Upload} from 'antd'
import {API} from '@eitje/easy_api'
import _ from 'lodash'
import {Link} from 'components/routing'
import {navigateModal} from 'actions/routing'

import {Form} from 'initializers/form'
import {DropdownPicker, Input, DatePicker} from '@eitje/form-fields-web'
import moment from 'moment'
import {useSelector} from 'react-redux'
import useAll from 'hooks/use_all'
import useFind from 'hooks/use_find'
import utils from '@eitje/web_utils'
import useExtend from 'hooks/use_extend'
import useTable from 'hooks/use_table'
import {ModalLink, ModalLinkBtn, Redirect} from 'components/routing'
import Modal, {ModalContext} from 'components/general/modal'
import UserInfo from 'pages/environments/user_info'
import BillingInfo from 'pages/environments/billing_info'
import Notes from 'pages/environments/notes'
import Invoice from 'pages/environments/invoice'
import CompetitorImport from 'pages/environments/competitor_import'
import useParams from 'hooks/use_params'
import useGet from 'hooks/use_get'
import {PrivateRoute, PublicRoute, AdminRoute, ModeratorRoute} from 'components/PrivateRoute'
import EnvForm from './form'
import {useRouteMatch, Switch} from 'react-router-dom'
import {InfoTooltip} from 'components/ui'

const {TabPane} = Tabs

const Show = () => {
	const {id} = useParams()
	const item = useFind('environments', id)
	const {billing_env_names = []} = item
	const {path, url} = useRouteMatch()
	useEffect(() => {
		API.show('environments', id)
	}, [])

	return (
		<Modal>
			<ModalLink to={`${url}/edit`} text="Vestiging informatie" />
			<ModalLink to={`${url}/users`} text="Teamleden informatie" />
			<ModalLink to={`${url}/billing_info`} text="Factuur informatie" />
			<ModalLink to={`${url}/notes`} text="Notities" />
			<ModalLink to={`${url}/competitor_import`} text="Import teamleden" />

			<Switch>
				<PrivateRoute path={`${path}/edit`}>
					<EnvForm initialValues={item} />
				</PrivateRoute>
				<PrivateRoute path={`${path}/users`}>
					<UserInfo baseUrl={url} item={item} />
				</PrivateRoute>
				<PrivateRoute path={`${path}/billing_info`}>
					<BillingInfo item={item} />
				</PrivateRoute>
				<PrivateRoute path={`${path}/notes`}>
					<Notes item={item} />
				</PrivateRoute>

				<PrivateRoute path={`${path}/competitor_import`}>
					<CompetitorImport item={item} />
				</PrivateRoute>

				<PrivateRoute path={`${path}/invoices/:id`}>
					<Invoice item={item} />
				</PrivateRoute>
				<Redirect to={`${url}/edit`} />
			</Switch>

			{billing_env_names?.length > 1 && <MultipleBillingEnvs names={billing_env_names} />}
		</Modal>
	)
}

const MultipleBillingEnvs = ({names = []}) => {
	return (
		<div>
			Vestiging valt in gezamenlijke billing!
			<InfoTooltip title={names.join(', ')} />
		</div>
	)
}

export default Show

//environments/104 => edit
//environments
