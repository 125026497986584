import {usePicker} from '@eitje/form'
import utils from '@eitje/web_utils'
import useSearch from 'hooks/use_search'
import {useBoolState} from '@eitje/react-hooks'
import {t} from 'initializers/i18n'
import _ from 'lodash'
import React, {Fragment, useState} from 'react'
import {filterItems} from '@eitje/easy_api'

const useGroupedItems = props => {
  let {items = [], groupField, groupItems, groupFilter, unassignedLabel, groupIdField = 'id', groupLabelField = 'name'} = props
  const {pickerItems} = usePicker({...props, items: props.groupItems, labelField: groupLabelField, valueField: groupIdField})
  const groupValues = _.uniq(items.map(i => i[groupField]).flat())
  const getItemsByGroup = val => filterItems(items, {[groupField]: val})

  let visibleGroups = pickerItems.filter(g => groupValues.includes(g[groupIdField]))

  if (utils.objPresent(groupFilter)) visibleGroups = filterItems(visibleGroups, groupFilter)

  const nonGroupedItems = items.filter(i => _.isArray(i[groupField]) && i[groupField].length === 0)

  return {groups: pickerItems, visibleGroups, nonGroupedItems, getItemsByGroup}
}

export default useGroupedItems
