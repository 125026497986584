import React, {useState, useEffect, useRef, useContext, Fragment} from 'react'
import api from 'initializers/api'
import * as jsUtils from 'utils/jsutils'
import * as DB from 'actions/entities'
import _ from 'lodash'
import {Link, useLocation} from 'react-router-dom'
import {Input, DatePicker, DropdownPicker, Switch} from '@eitje/form-fields-web'
import moment from 'moment'
import {Button} from 'antd'
import {useSelector} from 'react-redux'
import Modal from 'components/general/modal'
import useGet from 'hooks/use_get'
import useParams from 'hooks/use_params'
import {Spin} from 'antd'
import {ModalForm} from 'initializers/form'
import {all, where, API} from '@eitje/easy_api'
import InputNumber from 'components/form/input_number'
import useBackgroundEnvs from 'hooks/use_background_envs'

const _Form = ({initialValues = {activity_based: true}, ...props}) => {
  const envs = useBackgroundEnvs()
  return (
    <ModalForm onSubmit={data => API.update('billing_agreements', data)} initialValues={initialValues} {...props}>
      <InputNumber label="Korting in euros" field="discount_amt" />
      <InputNumber label="Korting per gebruiker in euros" max={4} field="reduction_amt_per_user" />
      <InputNumber label="Korting in %" max={100} field="discount_pct" />
      <InputNumber label="Vast bedrag per vestiging" field="fixed_amt_per_env" />
      <Switch field="activity_based" />
      <DropdownPicker
        showSearch
        required={!initialValues}
        multiple
        labelField="naam"
        label="custom envs"
        items={envs}
        field="environment_ids"
      />
      <Button submitButton> submit </Button>
    </ModalForm>
  )
}

export default _Form
