import React, {useEffect, useState, Fragment, createContext, useContext, useCallback, useRef} from 'react'
import useSelector from 'hooks/use_selector'
import moment from 'moment'
import Form from 'initializers/form'
import {DropdownPicker, Input, DatePicker, Switch} from '@eitje/form-fields-web'
import {t} from 'initializers/i18n'
import {API, useWhere, useFind, useAll} from '@eitje/easy_api'
import {useMergeState} from '@eitje/react-hooks'
import Modal from 'components/general/modal'
import Dropdown from 'components/dropdown'
import {Horizontal, Button, Tooltip, UserImage, EitjeButton, OptionsButton, DeleteButton, FormRow, Popover} from 'components/ui'
import utils from '@eitje/web_utils'
import useParams from 'hooks/use_params'
import {ModalLinkBtn, ModalLink, Link} from 'components/routing'

import {createMoment} from 'helpers/date'
import {useToggleState, useBoolState} from '@eitje/react-hooks'
import {history} from 'index'

import {useForm, ContextForm} from '@eitje/form'
import {renderCell, Aggregates} from 'shared/table/index'
import {Virtuoso} from 'react-virtuoso'
import {flexRender} from '@tanstack/react-table'
import './styles/index.less'
import _ from 'lodash'

const TableRows = ({rows, RowWrapper = Fragment, ...props}) => {
  const meta = rows[0]

  return (
    <tbody className="eitje-table-body">
      <div className="eitje-virtuoso-wrapper">
        <TableRowDecider rows={rows} RowWrapper={RowWrapper} {...props} />
      </div>
    </tbody>
  )
}

const TableRowDecider = ({rows, meta = {}, ...props}) => {
  const {virtual} = meta
  if (virtual)
    return (
      <Virtuoso
        data={rows}
        useWindowScroll
        itemContent={(index, row) => <TableRowWrapper key={row?.original?.id || index} row={row} {...props} />}
      />
    )

  return rows.map(r => <TableRowWrapper row={r} {...props} />)
}

const TableRowWrapper = ({row, RowWrapper, ...props}) => {
  return (
    <RowWrapper row={row}>
      <tbody className="eitje-table-row-wrapper">
        <TableRow {...props} row={row} />
      </tbody>
    </RowWrapper>
  )
}

const TableRow = ({row, onRowClick = _.noop}) => {
  const {getVisibleCells} = row
  const cells = getVisibleCells()
  const canExpand = row.getCanExpand()
  const classNames = utils.makeCns('eitje-table-row', canExpand && 'eitje-table-row-expandable')
  const _onClick = canExpand ? row.getToggleExpandedHandler() : () => onRowClick(row.original)

  return (
    <tr onClick={_onClick} className={classNames} key={row.id}>
      {cells.map(c => (
        <Cell row={row} cell={c} />
      ))}
    </tr>
  )
}

const Cell = ({row, cell}) => {
  const className = cell.column.id
  const classNames = utils.makeCns(`eitje-table-body-cell ${className}`)
  if (row.getCanExpand()) return <AggCell row={row} classNames={classNames} cell={cell} />
  return (
    <td className={classNames} key={cell.id}>
      {renderCell(cell)}
    </td>
  )
}

const AggCell = ({row, cell, classNames}) => {
  const rows = row.subRows
  const {column} = cell
  const {columnDef} = column
  const {groupedCell, hasAggCell, skipAggregates, cell: cellFn, aggregatedCell} = columnDef
  const fn = column.getAggregationFn()

  if (skipAggregates) return <td className={classNames} />

  const _cellFn = groupedCell || (hasAggCell && aggregatedCell) || cellFn
  return (
    <td className={classNames} key={cell.id}>
      {flexRender(_cellFn, cell.getContext())}
    </td>
  )
}

export default TableRows
